import { SearchIcon } from "components/icon";
// import { Input } from "./index";
import styles from "./SearchBox.module.css";
import { Input } from "antd";

export const SearchBox = (props) => {
  const {
    style,
    onClick,
    ...rest
  } = props;

  return (
    <div className={styles.search_box} style={style}>
      <Input
        {...rest}
      />
      <button type="button" onClick={onClick}>
        <SearchIcon />
      </button>
    </div>
  )
}
