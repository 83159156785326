import createIcon from "components/icon/createIcon"

export const HelpCircleGrayIcon = (props) => {
  return createIcon({
    size: props.size,
    path: <>
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#8B9099" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2577 8.27417C11.8038 8.19632 11.3371 8.28161 10.94 8.51494C10.543 8.74828 10.2414 9.11459 10.0886 9.54901C9.95111 9.93975 9.52292 10.1451 9.13218 10.0076C8.74144 9.87017 8.53611 9.44198 8.67356 9.05124C8.94393 8.28266 9.47759 7.63456 10.18 7.22174C10.8824 6.80892 11.7083 6.65802 12.5113 6.79576C13.3143 6.9335 14.0427 7.35099 14.5674 7.9743C15.0921 8.5976 15.3793 9.38649 15.3781 10.2012C15.3759 11.6528 14.4504 12.53 13.663 13.0028C13.3061 13.2171 12.9545 13.3646 12.6781 13.462V14C12.6781 14.4142 12.3423 14.75 11.9281 14.75C11.5138 14.75 11.1781 14.4142 11.1781 14V12.9001C11.1781 12.543 11.4299 12.2354 11.7799 12.1649L11.7789 12.1651C11.7787 12.1652 11.7789 12.1651 11.7799 12.1649L11.7922 12.1622C11.8054 12.1592 11.8273 12.154 11.8568 12.1464C11.9159 12.1312 12.0043 12.1065 12.112 12.0705C12.3299 11.9977 12.6135 11.8833 12.8908 11.7169C13.4525 11.3795 13.877 10.9067 13.8781 10.199C13.8788 9.7385 13.7164 9.2926 13.4199 8.9403C13.1233 8.588 12.7116 8.35202 12.2577 8.27417ZM11.2501 16.5001C11.2501 16.0859 11.5859 15.7501 12.0001 15.7501H12.0091C12.4233 15.7501 12.7591 16.0859 12.7591 16.5001C12.7591 16.9143 12.4233 17.2501 12.0091 17.2501H12.0001C11.5859 17.2501 11.2501 16.9143 11.2501 16.5001Z" fill="white" />
    </>
  })
} 
