import styles from "./SubscriptionFeePage.module.css"; 
import React, {  useState, useEffect } from "react";

import { useParams} from "react-router-dom";
import {  callApi } from "utils";

export const Terms = (props) => { 
  const [data, setData] = useState([]);
  const [title, setTitle] = useState(""); 

  const { id } = useParams(); 
  useEffect(()=>{
    const getContent = async () => { 

      

        const postData ={"coId":id};

        const response = await callApi('api/getContent', postData);
 
        if (response.result) {
          setTitle(response.coTitle);
          setData(response.coContent);
        } else {
          
        }  
    }
    getContent();
  }, [id]);
  return (
    <div className={styles.container}>
      <div className={"section"} style={{padding:20}}>


        <div dangerouslySetInnerHTML={{__html: title }} style={{fontSize:20, fontWeight:"bold"}} >
        
        </div>
        <div dangerouslySetInnerHTML={{__html: data }}  style={{marginTop:60, lineHeight:1.5 }}>
        
        </div>
        
       
         
      </div>
    </div>
  )
}