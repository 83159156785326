import React, { useRef, useState } from "react";
import { Button, CheckBox, Flex, IconButton } from 'components/hulam_platform';
import { ChevronDownIcon } from "components/icon";
import { Link, useNavigate } from "react-router-dom";
import { alert, callApi, extractNumbers, handlerEventLog, snackBar } from "utils";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./Join.module.scss";
import { useAuth } from "AuthProvider";
import { Form, Input, message } from "antd";

export const Join = () => {
  const [form] = Form.useForm();
  const [ overIdLength, setOverIdLength] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);

  const [captcha, setCaptcha] = useState(false);

  const [checkBoxList, setCheckBox] = useState({
    checkBox1: false,
    checkBox2: false,
    checkBox3: false,
    checkBox4: false,
  })
  const { login } = useAuth();
  const navigate = useNavigate();

  const allChecked = checkBoxList.checkBox1 && checkBoxList.checkBox2 && checkBoxList.checkBox3 && checkBoxList.checkBox4;

  /**@function handleChecked()
   * 모두 동의 체크 클릭 이벤트
   * allChecked ? 모두 체크 : 모두 체크 해제
   */
  const handleChecked = () => setCheckBox({
    checkBox1: !allChecked,
    checkBox2: !allChecked,
    checkBox3: !allChecked,
    checkBox4: !allChecked,
  })

  /**@function handleAgreeBox()
   * 모두 동의 박스 on off
   */
  const handleAgreeBox = () => {
    if (agreeBoxRef?.current && arrowRef?.current) {
      const { event } = agreeBoxRef.current.dataset;

      if (event === "on") {
        agreeBoxRef.current.dataset.event = "off"
        arrowRef.current.dataset.icon = "off"
      } else {
        agreeBoxRef.current.dataset.event = "on"
        arrowRef.current.dataset.icon = "on"
      }
    }
  }

  const agreeBoxRef = useRef(null);
  const arrowRef = useRef(null);

  const sendJoinRequest = async (values) => {
    snackBar("처리중입니다.", {
      type: "loading",
      duration: '0'
    });

    setIsLoading(true);

    const postData = {
      userId: values.userId,
      companyName: values.companyName,
      president: values.president,
      phone: `${values.phone1}${values.phone2}${values.phone3}`,
      companyNumber: `${values.companyNumber1}${values.companyNumber2}${values.companyNumber3}`,
      email: values.email,
      password: values.password,
      passwordConfirm: values.passwordConfirm,
    }

    const response = await callApi('api/platformJoin', postData);
    
    message.destroy();

    if (response.result) {
      const response = await callApi('api/platformLogin', postData);
      const dateOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false
      };

      const toDay = new Intl.DateTimeFormat('ko-KR',dateOptions).format(new Date());

      const eventLog = {
        href: window.location.href,
        event_type: 'signup',
        userId: values.userId,
        category: 'member_status',
        message: "",
        source: "",
        label1: "",
        label2: "",
        slack_message: `[회원가입]\n업무 대행사 회사명:${values.companyName} (${values.userId})\n대표자명:${values.president}\n아이디:${values.userId}\n전화번호:${values.phone1}${values.phone2}${values.phone3}\n이메일:${values.email}\n가입일시: ${toDay}`,
        slack_type : 'register'
      }
      await handlerEventLog(eventLog)

      login(response, "manager");
      navigate("/hulamHome", { state: 'initLogin' });
    } else {
      setIsLoading(false);
      alert({ content: "회원 가입에 실패했어요." })
    }

  }

  const onFinishFailed = () => {
    alert({ content: "필수값을 입력해주세요." })
  };


  
  return <div style={{ marginTop: 120 }}>
    <div style={{
      margin: "0px auto",
      padding: 30,
      fontSize: 34,
      textAlign: "center",
      fontWeight: 700,
    }}>
      업무대행사 가입
    </div>
    <div style={{ margin: "65px auto", width: 455 }}>
      <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "left" }}>
        계정 정보
      </div>
      <Form
        form={form}
        autoComplete="off"
        style={{
          display: 'flex',
          flexDirection: "column",
          rowGap: 20,
          width: "100%",
          maxWidth: 455,
          paddingTop: 30
        }}
        colon={false}
        onFinish={sendJoinRequest}
        onFinishFailed={onFinishFailed}
        onValuesChange={(changedValues) => {
          if (changedValues.userId?.length > 20) {
            setOverIdLength(true)
          } else {
            setOverIdLength(false)
          }
        }}
      >
        <Flex direction="column">
          <Flex justifyContent="space-between" style={{ width: '100%' }}>
            <div style={{ fontWeight: "bold" }}>아이디<span className="required">*</span></div>
            <Form.Item
              name={"userId"}
              noStyle
              rules={[
                { required: true },
                {
                  max: 20,
                  message: "아이디는 20자를 넘길 수 없습니다."
                }
              ]}
            >
              <Input style={{ width: 315 }}/>
            </Form.Item>
          </Flex>
          <Flex justifyContent="space-between" style={{ width: '100%' }}>
            <span></span>
            {
              overIdLength ? 
              <span style={{ width: 315, color: "var(--primary-red-500---main-color, #FF4E4E)"}}>아이디는 20자를 넘길 수 없습니다.</span>
              :
              <span style={{ width: 315 }}>영문자, 숫자, _만 허용</span>
            }
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <div style={{ fontWeight: "bold" }}>회사명<span className="required">*</span></div>
          <Form.Item
            name={"companyName"}
            noStyle
            rules={[{ required: true }]}
          >
            <Input style={{ width: 315 }}/>
          </Form.Item>
        </Flex>
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <div style={{ fontWeight: "bold" }}>대표자명<span className="required">*</span></div>
          <Form.Item
            name={"president"}
            noStyle
            rules={[{ required: true }]}
          >
            <Input style={{ width: 315 }} />
          </Form.Item>
        </Flex>
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <div style={{ fontWeight: "bold" }}>사업자 등록번호<span className="required">*</span></div>
          <Flex style={{ width: 315 }}>
            <Form.Item
              name={"companyNumber1"}
              noStyle
              getValueFromEvent={extractNumbers}
              rules={[{ required: true }]}
            >
              <Input maxLength={3} />
            </Form.Item>
            -
            <Form.Item
              name={"companyNumber2"}
              noStyle
              getValueFromEvent={extractNumbers}
              rules={[{ required: true }]}
            >
              <Input maxLength={2} />
            </Form.Item>
            -
            <Form.Item
              name={"companyNumber3"}
              noStyle
              getValueFromEvent={extractNumbers}
              rules={[{ required: true }]}
            >
              <Input maxLength={5} />
            </Form.Item>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <div style={{ fontWeight: "bold" }}>담당자 휴대폰 번호<span className="required">*</span></div>
          <Flex style={{ width: 315 }}>
            <Form.Item
              name={"phone1"}
              noStyle
              getValueFromEvent={extractNumbers}
              rules={[{ required: true }]}
            >
              <Input maxLength={3} />
            </Form.Item>
            -
            <Form.Item
              name={"phone2"}
              noStyle
              getValueFromEvent={extractNumbers}
              rules={[{ required: true }]}
            >
              <Input maxLength={4} />
            </Form.Item>
            -
            <Form.Item
              name={"phone3"}
              noStyle
              getValueFromEvent={extractNumbers}
              rules={[{ required: true }]}
            >
              <Input maxLength={4} />
            </Form.Item>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <div style={{ fontWeight: "bold" }}>담당자 이메일<span className="required">*</span></div>
          <Form.Item
            name={"email"}
            noStyle
            rules={[{ required: true }]}
          >
            <Input style={{ width: 315 }} />
          </Form.Item>
        </Flex>
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <div style={{ fontWeight: "bold" }}>비밀번호<span className="required">*</span></div>
          <Form.Item
            name={"password"}
            noStyle
            rules={[{ required: true ,
              message: ''},
              () => ({
                validator(_, value) {
                  const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
                  if (!pattern.test(value)) {
                    return Promise.reject(new Error('비밀번호 규칙이 맞지 않습니다.'));
                  }

                  return Promise.resolve();
                },
              })  ]}
          >
            <Input.Password 
            
            style={{ width: 315 }} />
          </Form.Item>
       
        
        </Flex>  
        <Flex justifyContent="space-between" style={{ width: '100%' ,marginTop:-14 }}>
            <span></span> <span style={{ width: 315 }}>영문, 숫자, 특수문자 3종류 이상을 조합하여 최소 8자리 이상의 길이를 입력해주세요.</span>
        </Flex>
       
        <Flex justifyContent="space-between" style={{ width: '100%' }}>
          <div style={{ marginBottom: 24, fontWeight: "bold" }}>비밀번호 확인<span className="required">*</span></div>
          <Form.Item
            name={"passwordConfirm"}
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: '',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
                 
                  if (!pattern.test(value)) {
                    return Promise.reject(new Error('비밀번호 규칙이 맞지 않습니다.'));
                  }
                  if (!pattern.test(getFieldValue('password'))) {
                    return Promise.reject(new Error('비밀번호 규칙이 맞지 않습니다.'));
                  }

                  
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('패스워드가 맞지 않습니다.'));
                },
              }) 
            ]}
          >
            <Input.Password style={{ width: 315 }} />
          </Form.Item>
        </Flex>
      </Form>

      <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "left", marginTop: 70 }}>
        자동 등록 방지
        <div style={{ marginTop: 20 }}>
          <ReCAPTCHA
            sitekey={"6Lc-pE4pAAAAACIxsKAm2N-6EpQzJrS9cnQCxKzQ"}
            hl="ko"
            onChange={() => setCaptcha(true)}
          />
        </div>
      </div>
      <div style={{ fontSize: 24, fontWeight: "bold", textAlign: "left", marginTop: 70 }}>
        약관 동의
      </div>
      <div style={{ textAlign: "left", marginTop: 20 }}>
        <Flex justifyContent="space-between">
          <CheckBox style={{ fontWeight: 700, fontSize: 16 }} checked={allChecked} onClick={() => handleChecked()} readOnly>
            모두 동의
          </CheckBox>
          <IconButton htmlRef={arrowRef} className={styles.arrowIcon} style={{ border: "none" }} onClick={() => handleAgreeBox()} data-icon="on">
            <ChevronDownIcon />
          </IconButton>
        </Flex>
        <hr style={{ width: "100%", margin: "20px 0", border: "1px solid var(--gray-200-divider)" }} />
        <div ref={agreeBoxRef} className={`${styles.agree_check_list_wrap}`} data-event="on">
          <Flex justifyContent="space-between">
            <CheckBox style={{ width: "100%" }} checked={checkBoxList.checkBox1} onChange={(e) => setCheckBox({ ...checkBoxList, checkBox1: e.target.checked })}>
              (필수) 서비스 이용 약관에 동의합니다.
            </CheckBox>
            <Link to="/terms/hulampro_service2024" target="_blank">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z" fill="#E2E5EA" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.9087 15.6008C10.6643 15.3571 10.6637 14.9614 10.9074 14.7169L13.4005 12.2164L10.9075 9.71641C10.6637 9.47199 10.6643 9.07627 10.9087 8.83253C11.1531 8.58879 11.5488 8.58933 11.7926 8.83375L14.7257 11.775C14.9689 12.0189 14.9689 12.4137 14.7257 12.6576L11.7926 15.5995C11.5489 15.8439 11.1532 15.8445 10.9087 15.6008Z" fill="#1A1818" />
              </svg>
            </Link>
          </Flex>
          <Flex justifyContent="space-between" style={{ marginTop: 20 }} >
            <CheckBox style={{ width: "100%" }} checked={checkBoxList.checkBox3} onChange={(e) => setCheckBox({ ...checkBoxList, checkBox3: e.target.checked })}>
              (필수) 개인정보 수집·이용에 동의합니다.
            </CheckBox>
            <Link to="/terms/hulampro_privacy2024" target="_blank">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z" fill="#E2E5EA" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.9087 15.6008C10.6643 15.3571 10.6637 14.9614 10.9074 14.7169L13.4005 12.2164L10.9075 9.71641C10.6637 9.47199 10.6643 9.07627 10.9087 8.83253C11.1531 8.58879 11.5488 8.58933 11.7926 8.83375L14.7257 11.775C14.9689 12.0189 14.9689 12.4137 14.7257 12.6576L11.7926 15.5995C11.5489 15.8439 11.1532 15.8445 10.9087 15.6008Z" fill="#1A1818" />
              </svg>
            </Link>
          </Flex>
          {
            (checkBoxList.checkBox1 && checkBoxList.checkBox2 && checkBoxList.checkBox3 && !checkBoxList.checkBox4) && (
              <div className="promotion_info" style={{ padding: 20, marginTop: 10, borderRadius: 10, background: "#F4F7FA", }}>
                <h3 style={{ fontSize: 18, fontWeight: 700, marginBottom: 20 }}>
                  🤚잠깐! 무료 이벤트 정보를 보내드려요.
                </h3>
                <p style={{ color: "#555" }}>1년간 95%의 고객사들이 이벤트 정보를 수신받았어요.</p>
                <h4 style={{ marginTop: 10, color: "#555" }}>🎉 고객사들이 받았던 깜짝 이벤트 혜택</h4>
                <ul style={{ marginBottom: 10 }}>
                  <li style={{ marginLeft: 20, listStyle: "disc", fontWeight: 500 }}>프리미엄 이용권 3개월 무료</li>
                  <li style={{ marginLeft: 20, listStyle: "disc", fontWeight: 500 }}>부당해고 신고에 대한 무료 상담 등 </li>
                </ul>
                <p style={{ color: "#555" }}>수시로 진행되는 <strong style={{ fontWeight: 500 }}>무료 이벤트 혜택</strong> 놓치지 마세요!</p>
              </div>
            )
          }
        </div>
      </div>

      <div style={{ marginTop: 70 }}>
        <Button
          style={{ width: "100%", height: 55 }}
          onClick={() => form.submit()}
          isDisabled={(captcha && checkBoxList.checkBox1 && checkBoxList.checkBox3) && !isLoading ? false : true}
        >
          가입하기
        </Button>
      </div>
      <div style={{ height: 100 }}></div>
    </div>
  </div>
}