import { CheckCircleOnIcon, CheckReturnOnIcon, InfoBlueIcon, InfoGrayIcon, RoundWarningIcon } from 'components/icon';
import React from 'react'
import styles from "./notification.module.css"

/**
 */
export const NotificationCard = (props) => {
  const {
    type = props.type ? props.type : "default",
    size = props.size ? props.size : "default",
    style = props.style ? props.style : '', 
    message,
    className="",
    ...rest
  } = props;

  const icon = (target, size = 24) => {
    const obj = {
      default: <InfoGrayIcon size={size} />,
      info: <InfoBlueIcon size={size} />,
      success: <CheckCircleOnIcon size={size} />,
      warning: <RoundWarningIcon size={size} />,
      error: <CheckReturnOnIcon size={size} />,
    }

    return obj[target];
  }

  const basicStyle = {
    ...style,
    display: style.display ? style.display : "inline-flex",
    columnGap: style.columnGap ? style.columnGap : 8,
    minHeight: props.size === "small" ? 36 : 65
  }

  return (
    <div
      type={type}
      style={basicStyle}
      className={`${styles.notification_card} ${className}`}
      {...rest}
    >
      {icon(type, size === "sm" ? 20 : 24)}
      <div className={styles.message}>
        {message}
      </div>
    </div>
  )
}
