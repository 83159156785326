import createIcon from "../createIcon"

export const SearchIcon = (props) => {
  return createIcon({
    size: props.size,
    path: <>
      <path d="M10.6747 3.44578C6.68866 3.44578 3.44578 6.68866 3.44578 10.6747C3.44578 14.6607 6.68866 17.9036 10.6747 17.9036C14.6607 17.9036 17.9036 14.6607 17.9036 10.6747C17.9036 6.68866 14.6607 3.44578 10.6747 3.44578ZM10.6747 2C15.4656 2 19.3494 5.8838 19.3494 10.6747C19.3494 15.4656 15.4656 19.3494 10.6747 19.3494C5.8838 19.3494 2 15.4656 2 10.6747C2 5.8838 5.8838 2 10.6747 2Z" fill="#1A1818" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.9467 15.9467C16.229 15.6644 16.6867 15.6644 16.969 15.9467L21.7883 20.7659C22.0706 21.0483 22.0706 21.506 21.7883 21.7883C21.506 22.0706 21.0483 22.0706 20.7659 21.7883L15.9467 16.969C15.6644 16.6867 15.6644 16.229 15.9467 15.9467Z" fill="#1A1818" />
    </>
  })
} 