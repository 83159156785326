import { Modal } from 'antd'
import { Button, MultiButton } from 'components/button'
import { Input } from 'components/input'
import React, { useState } from 'react'
import styles from "./FindModal.module.css"
import { callApi } from 'utils'
import { Flex } from 'components/flex'

export default function FindPasswordModal(props) {
  const [id, setId] = useState(null);
  const [email, setEmail] = useState(null);
  const [findResult, setFindResult] = useState({
    status: '',
    id: '',
    message: ''
  });

  const findId = async () => {
    const postData = {
      id: id,
      email: email
    }

    const response = callApi("", postData);

    if (response.status === "success") {
      setFindResult({
        ...findResult,
        status: "success",
        id: response.id,
      })
    } else {
      setFindResult({
        status: "error",
        id: response.id,
        message: '해당 이메일과 일치하는 아이디가 없어요.',
      })
    }
  }
  return (
    <Modal
      title={"회원정보 찾기"}
      open={props.open}
      closeIcon={false}
      centered
      width={600}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      styles={{
        body: {
          height: 450
        }
      }}
      footer={
        <MultiButton>
          <Button
            type="primary"
            onClick={() => findId()}
            width={125}
          >
            확인
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
    >
      <div className={styles.find_modal}>
        <div className={styles.find_modal_title_box}>
          <img src="https://hulam.co.kr/theme/hulam_next/resources/images/common/logo.svg" alt="휴램 로고" height="36" />
          <h2>비밀번호 찾기</h2>
        </div>
        {
          findResult === "success" ? (
            <>
              <p style={{ marginBottom: 15, fontSize: 14 }}>
                아이디 찾기 결과를 알려드립니다.
              </p>
              <p style={{ marginBottom: 15, fontSize: 14 }}>
                hul**
              </p>
            </>
          ) : (
            <>
              <p style={{ marginBottom: 15, fontSize: 14, textAlign: 'center' }}>
                아이디와 담당자 이메일 주소를 입력해 주세요.<br />
                아이디와 담당자 이메일 주소가 일치할 경우<br />
                해당 이메일 주소로 비밀번호 정보를 보내드립니다.
              </p>
              <Flex direction='column' style={{ width: '100%' }} alignItems='flex-start'>
                <Flex direction='column' style={{ width: '100%' }} rowGap={10}>
                  <Input
                    value={id}
                    status={findResult.status}
                    placeholder="아이디 입력"
                    style={{ width: "100%", height: 55 }}
                    onChange={(e) => setId(e.target.value)}
                  />
                  <Input
                    value={email}
                    status={findResult.status}
                    placeholder="이메일 주소 입력"
                    style={{ width: "100%", height: 55 }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Flex>
                {
                  findResult.message && <span className="error_message">{findResult.message}</span>
                }
              </Flex>
            </>
          )
        }

      </div>
    </Modal >
  )
}
