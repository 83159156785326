import { Modal } from 'antd'
import { Button, MultiButton } from 'components/button'
import { Input } from 'components/input'
import React, { useState } from 'react'
import styles from "./FindModal.module.css"
import { callApi } from 'utils'
import { Flex } from 'components/flex'
import logo from "assets/images/logo.svg"

export default function FindIdModal(props) {
  const [email, setEmail] = useState(null);
  const [findResult, setFindResult] = useState({
    status: '',
    id: '',
    message: ''
  });

  const findId = async () => {
    const postData = {
      email: email
    }

    const response = callApi("", postData);

    if (response.status === "success") {
      setFindResult({
        ...findResult,
        status: "success",
        id: response.id,
      })
    } else {
      setFindResult({
        status: "error",
        id: response.id,
        message: '해당 이메일과 일치하는 아이디가 없어요.',
      })
    }
  }
  return (
    <Modal
      title={"회원정보 찾기"}
      open={props.open}
      centered
      closeIcon={false}
      width={600}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      styles={{
        body: {
          height: 350
        }
      }}
      footer={
        <MultiButton>
          <Button
            type="primary"
            onClick={() => findId()}
            isDisabled={email?.length || findResult === "success" ? false : true}
            width={125}
          >
            확인
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
    >
      <div className={styles.find_modal}>
        <div className={styles.find_modal_title_box}>
          <img src={logo} alt="휴램 로고" />
          <h2>아이디 찾기</h2>
        </div>
        {
          findResult === "success" ? (
            <>
              <p style={{ marginBottom: 15, fontSize: 14 }}>
                아이디 찾기 결과를 알려드립니다.
              </p>
              <p style={{ marginBottom: 15, fontSize: 14 }}>
                hul**
              </p>
            </>
          ) : (
            <>
              <p style={{ marginBottom: 15, fontSize: 14 }}>
                담당자 이메일 주소를 입력해 주세요.
              </p>
              <Flex direction='column' style={{ width: '100%' }} alignItems='flex-start'>
                <Input
                  value={email}
                  status={findResult.status}
                  placeholder="이메일 주소 입력"
                  style={{ width: "100%", height: 55 }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {
                  findResult.message && <span className="error_message">{findResult.message}</span>
                }
              </Flex>
            </>
          )
        }

      </div>
    </Modal >
  )
}
