import createIcon from "components/icon/createIcon"

export const InfoBlueIcon = (props) => {
  return createIcon({
    size: props.size,
    path: <>
      <path d="M12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5Z" fill="#407DF1" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 17.3333C11.5398 17.3333 11.1667 16.9602 11.1667 16.5L11.1667 12.5C11.1667 12.0398 11.5398 11.6667 12 11.6667C12.4602 11.6667 12.8333 12.0398 12.8333 12.5L12.8333 16.5C12.8333 16.9602 12.4602 17.3333 12 17.3333ZM12.8333 8.5C12.8333 8.96024 12.4602 9.33333 12 9.33333L11.99 9.33333C11.5298 9.33333 11.1567 8.5 11.1567 8.5C11.1567 8.5 11.5298 7.66667 11.99 7.66667L12 7.66667C12.4602 7.66667 12.8333 8.03976 12.8333 8.5Z" fill="white" />
    </>
  })
} 
