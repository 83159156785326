import { Flex } from "components/flex"
import styles from "./SubscriptionFeePage.module.css";
import { Button, MultiButton } from 'components/button';
import { Collapse, Input, Table } from "antd"
import { useEffect, useState } from "react";

import faq_2_1 from "assets/images/faq_2_1.jpg";
import DisplayFeeReflectionProcessModal from "pages/Hulam/Subscription/modal/DisplayFeeReflectionProcessModal";
import { addCommas, extractNumbers } from "utils";
import { NotificationCard } from "components/notification";

const columns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '직원당 100원',
    dataIndex: 'price2',
    key: 'price2',
  },
  {
    title: '직원당 300원',
    dataIndex: 'price1',
    key: 'price1',
  },
];

const data = [
  {
    key: '8',
    name: <span className={styles.price_title}>노무 관리</span>,
    price1: "",
    price2: "",
  },
  {
    key: '9',
    name: '인사DB관리',
    price1: "O",
    price2: "O",
  },
  {
    key: '10',
    name: '근로관계 서식 출력',
    price1: "O",
    price2: "O",
  },
  {
    key: '11',
    name: '근로계약서 자동 작성',
    price1: "O",
    price2: "O",
  },
  {
    key: '12',
    name: '근태 관리',
    price1: "O",
    price2: "O",
  },
  {
    key: '13',
    name: '연차 휴가 관리',
    price1: "O",
    price2: "O",
  },
  {
    key: '14',
    name: '휴직 관리',
    price1: "O",
    price2: "O",
  },
  {
    key: '15',
    name: '퇴사 관리',
    price1: "O",
    price2: "O",
  },
  {
    key: '16',
    name: '급여 관리',
    price1: "O",
    price2: "O",
  },
  {
    key: '3',
    name: <span className={styles.price_title}>4대 보험 보험료•고지내역</span>,
    price1: "",
    price2: "",
  },
  {
    key: '4',
    name: '건강보험 고지내역 가져오기',
    price1: "O",
    price2: "",
  },
  {
    key: '5',
    name: '국민연금 결정내역 가져오기',
    price1: "O",
    price2: "",
  },
  {
    key: '6',
    name: '고용보험 당월 보험료 가져오기',
    price1: "O",
    price2: "",
  },
  {
    key: '7',
    name: '고용보험 실업급여 지원금 가져오기',
    price1: "O",
    price2: "",
  },
  {
    key: '1',
    name: <span className={styles.price_title}>4대 보험 EDI 연동</span>,
    price1: "",
    price2: "",
  },
  {
    key: '2',
    name: '수임업체 가져오기',
    price1: "O",
    price2: "O",
  },
]

const ArrowDownIcon = () => {
  return <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 1C8.5 0.447715 8.05228 2.41411e-08 7.5 0C6.94772 -2.41411e-08 6.5 0.447715 6.5 1L8.5 1ZM6.79289 16.7071C7.18342 17.0976 7.81658 17.0976 8.20711 16.7071L14.5711 10.3431C14.9616 9.95262 14.9616 9.31946 14.5711 8.92893C14.1805 8.53841 13.5474 8.53841 13.1569 8.92893L7.5 14.5858L1.84315 8.92893C1.45262 8.53841 0.819456 8.53841 0.428932 8.92893C0.0384074 9.31946 0.0384074 9.95262 0.428932 10.3431L6.79289 16.7071ZM6.5 1L6.5 16L8.5 16L8.5 1L6.5 1Z" fill="#ACB1BA" />
  </svg>
}

const ArrowRightIcon = () => {
  return <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM1 9H16V7H1V9Z" fill="#ACB1BA" />
  </svg>
}

const FAQ1 = () => {
  return <div>
    <p className={styles.answer}>A. ‘이용권 신청' 후 이용권이 부여되면 결제일에 후불 결제 청구됩니다.</p>
    <div className={styles.fee_container}>
      <div className={styles.item_container}>
        <span className={styles.title_name}>휴램프로 사용</span>
        <ol className={styles.list_wrap}>
          <li className={styles.item}>4대 보험 EDI에서<br />수임업체 가져옴</li>
          <li><ArrowRightIcon /></li>
          <li className={styles.item}>수임업체에<br />4대 보험료<br />산출•고지내역<br />자동설정 ON/OFF</li>
          <li><ArrowRightIcon /></li>
          <li className={styles.item}>수임업체 사업장에<br />직원 등록</li>
          <li><ArrowRightIcon /></li>
          <li className={styles.item} style={{ textAlign: "left" }}>사업장 관리<br />&nbsp;&nbsp;•급여대장<br />&nbsp;&nbsp;•근로계약서</li>
        </ol>
      </div>
      <ArrowDownIcon />
      <div className={styles.item_container}>
        <span className={styles.title_name}>결제일</span>
        <ol>
          <li className={styles.item}>과금 대상<br />직원수 계산</li>
          <li><ArrowRightIcon /></li>
          <li className={styles.item}>후불 결제 청구</li>
        </ol>
      </div>
    </div>
  </div>
}


const FAQ2 = () => {
  return <div style={{ overflow: 'scroll' }}>
    <p className={styles.answer}>A.</p>
    <div className={styles.faq2_container}>
      <img src={faq_2_1} width={568} alt="전월 귀속연월 급여대장의 사원수 + 재직중 사원수 (근로계약서 확정 상태)" />
      <div style={{ display: "flex", flexDirection: 'column' }}>
        <div className={styles.table_tr}>
          <div className={styles.table_td}>4대 보험료 산출&middot;고지내역 연동</div>
          <div className={styles.table_td}>수임업체의 과금액</div>
        </div>
        <div className={styles.table_tr}>
          <div className={styles.table_td}>설정 ON</div>
          <div className={styles.table_td}>C명 X 300원</div>
        </div>
        <div className={styles.table_tr}>
          <div className={styles.table_td}>설정 OFF</div>
          <div className={styles.table_td}>C명 X 100원</div>
        </div>
      </div>
    </div>
  </div>
}


const FAQ3 = () => {
  return <p className={styles.answer}>A. ①회원 가입 ②로그인 후에 이용권 메뉴 &#62; 이용권 신청에서 진행 가능합니다.</p>
}




const items = [
  {
    key: '1',
    label: 'Q. 이용권 과금액은 어떻게 청구되나요?',
    children: <FAQ1 />,
  },
  {
    key: '2',
    label: 'Q. 과금 대상 직원은 어떻게 집계되나요?',
    children: <FAQ2 />,
  },
  {
    key: '3',
    label: 'Q. 이용권은 어떻게 구매하나요?',
    children: <FAQ3 />,
  },
];


export const SubscriptionFeePage = () => {
  const [monthPrice, setMonthPrice] = useState(0);
  const [processModalOpen, setProcessModalOpen] = useState(false);
  const [selectButton, setSelectButton] = useState("on");
  const [employeeCount, setEmployeeCount] = useState('');

  useEffect(() => {
    if (selectButton === "on") {
      setMonthPrice(addCommas(300 * employeeCount))
    } else {
      setMonthPrice(addCommas(100 * employeeCount))
    }
  }, [selectButton, employeeCount])

  return (
    <div className={styles.container}>
      <div className={"section"}>
        <Flex direction="column" alignItems="flex-start" rowGap={40} style={{ marginBottom: 120 }}>
          <h4 className={styles.title}>
            비용 안내
          </h4>
          <NotificationCard
            type="info"
            size="small"
            message={"현재는 무료 사용이 가능해요. 자유롭게 테스트해 보세요."}
          />
          <Flex
            style={{ width: '100%', height: 280, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '16px', flexShrink: 0, borderRadius: '6px', background: 'var(--gray-50-background-divider, #F3F4F8)', fontSize: '16px' }}>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M51.3334 28.0001C51.3334 15.1134 40.8867 4.66675 28.0001 4.66675C15.1134 4.66675 4.66675 15.1134 4.66675 28.0001C4.66675 40.8867 15.1134 51.3334 28.0001 51.3334C40.8867 51.3334 51.3334 40.8867 51.3334 28.0001Z"
                fill="#E2E5EA" />
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M28.0001 16.8195C29.3424 16.8195 30.4306 17.9077 30.4306 19.2501V28.0001C30.4306 29.3424 29.3424 30.4306 28.0001 30.4306C26.6577 30.4306 25.5695 29.3424 25.5695 28.0001V19.2501C25.5695 17.9077 26.6577 16.8195 28.0001 16.8195ZM25.5695 36.7501C25.5695 35.4077 26.6577 34.3195 28.0001 34.3195H28.022C29.3643 34.3195 30.4525 35.4077 30.4525 36.7501C30.4525 38.0924 29.3643 39.1806 28.022 39.1806H28.0001C26.6577 39.1806 25.5695 38.0924 25.5695 36.7501Z"
                    fill="#ACB1BA" />
            </svg>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: 16, textAlign: 'center' }}>
              <span>이용요금은 11월 공개됩니다.</span>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                기본 정책
                <ul
                  style={{ display: 'flex', gap: 4, flexDirection: 'column', fontSize: 14 }}>
                  <li>
                    &middot; 무료체험 1개월
                  </li>
                  <li>
                    &middot; 체험 종료 후 1개월의 사용 후, 말일 청구
                  </li>
                </ul>
              </div>
            </div>
          </Flex>
        </Flex>
        <Flex direction="column" alignItems="flex-start" rowGap={40}
              style={{ marginBottom: 120, display: 'none' }}>
          <Flex direction="column" alignItems="flex-start" rowGap={24}
                style={{ width: '100%' }}>
            <h4 className={styles.title}>
              비용 안내
              <Button type="line-gray" size="sm"
                      onClick={() => setProcessModalOpen(true)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M9.99999 2.94871C6.10567 2.94871 2.94871 6.10567 2.94871 9.99999C2.94871 13.8943 6.10567 17.0513 9.99999 17.0513C13.8943 17.0513 17.0513 13.8943 17.0513 9.99999C17.0513 6.10567 13.8943 2.94871 9.99999 2.94871ZM1.66666 9.99999C1.66666 5.39762 5.39762 1.66666 9.99999 1.66666C14.6024 1.66666 18.3333 5.39762 18.3333 9.99999C18.3333 14.6024 14.6024 18.3333 9.99999 18.3333C5.39762 18.3333 1.66666 14.6024 1.66666 9.99999ZM10.2202 6.81541C9.83228 6.74887 9.43332 6.82177 9.09398 7.0212C8.75465 7.22063 8.49684 7.53372 8.36623 7.90502C8.24875 8.23899 7.88278 8.41448 7.54881 8.297C7.21484 8.17952 7.03935 7.81354 7.15683 7.47958C7.38791 6.82267 7.84403 6.26874 8.44439 5.9159C9.04475 5.56307 9.75061 5.43409 10.437 5.55182C11.1233 5.66954 11.7458 6.02638 12.1943 6.55911C12.6428 7.09185 12.8882 7.76612 12.8872 8.46248C12.8853 9.70317 12.0943 10.4529 11.4213 10.857C11.1162 11.0402 10.8157 11.1662 10.5795 11.2495V11.7093C10.5795 12.0633 10.2925 12.3503 9.93845 12.3503C9.58442 12.3503 9.29743 12.0633 9.29743 11.7093V10.7692C9.29743 10.4644 9.51204 10.2019 9.81054 10.1411C9.81089 10.141 9.81123 10.1409 9.81157 10.1409L9.82232 10.1385C9.83358 10.1359 9.85235 10.1315 9.87755 10.125C9.92807 10.112 10.0036 10.0909 10.0956 10.0601C10.2819 9.99794 10.5243 9.90018 10.7613 9.75789C11.2414 9.46958 11.6042 9.06542 11.6051 8.46057C11.6057 8.06697 11.467 7.68587 11.2135 7.38476C10.96 7.08364 10.6082 6.88195 10.2202 6.81541ZM9.35896 13.8461C9.35896 13.4921 9.64596 13.2051 9.99999 13.2051H10.0077C10.3617 13.2051 10.6487 13.4921 10.6487 13.8461C10.6487 14.2002 10.3617 14.4872 10.0077 14.4872H9.99999C9.64596 14.4872 9.35896 14.2002 9.35896 13.8461Z" fill="#1A1818" />
                </svg>
                이용 요금 반영 과정
              </Button>
            </h4>
            <NotificationCard
              type="info"
              size="small"
              message={
                <>
                  지금 회원가입 후 이용권 신청하면 2024.05.31 까지 과금 없이 무료로 사용할 수 있어요.
                </>
              }
            />
            <Table
              className={styles.table}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
            <div className={styles.noti_container}>
              <h4>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.17946 7.11233C6.76322 7.10179 6.34908 7.17467 5.96145 7.32667C5.57381 7.47868 5.22052 7.70673 4.9224 7.9974C4.62428 8.28807 4.38735 8.63547 4.22558 9.01913C4.06381 9.4028 3.98047 9.81496 3.98047 10.2313C3.98047 10.6477 4.06381 11.0599 4.22558 11.4435C4.38735 11.8272 4.62428 12.1746 4.9224 12.4653C5.22052 12.7559 5.57381 12.984 5.96145 13.136C6.34908 13.288 6.76322 13.3609 7.17946 13.3503C7.31656 13.3505 7.44934 13.3023 7.55446 13.2143C7.65826 13.127 7.72713 13.0053 7.74846 12.8713H7.75746V7.68033C7.75717 7.60579 7.74208 7.53204 7.71307 7.46338C7.68406 7.39471 7.6417 7.33249 7.58846 7.28033C7.47941 7.17289 7.33254 7.11256 7.17946 7.11233Z" fill="#1A1818" />
                  <path d="M20.0022 4.56799C20.002 4.49344 19.9869 4.41968 19.9579 4.35101C19.9289 4.28233 19.8865 4.22012 19.8332 4.16799C19.7335 4.07024 19.6019 4.0117 19.4625 4.00306C19.3231 3.99443 19.1853 4.0363 19.0742 4.12099L13.9842 6.99999H9.40222C9.24942 6.99969 9.10259 7.05928 8.99322 7.16599C8.93993 7.21812 8.89754 7.28033 8.86853 7.34901C8.83951 7.41768 8.82445 7.49144 8.82422 7.56599V12.959H8.83122C8.84853 13.0967 8.91621 13.2232 9.02122 13.314C9.12692 13.4059 9.26217 13.4566 9.40222 13.457H13.4722L19.0362 16.61C19.1462 16.7102 19.2903 16.7645 19.439 16.7619C19.5877 16.7593 19.7298 16.6999 19.8362 16.596C19.8895 16.5439 19.9319 16.4816 19.9609 16.413C19.9899 16.3443 20.005 16.2705 20.0052 16.196C20.0044 16.1749 20.0024 16.1539 19.9992 16.133H20.0022V4.59999C20.0022 4.58999 20.0022 4.57899 20.0022 4.56799Z" fill="#1A1818" />
                  <path d="M14.1219 18.574L14.1139 18.56C14.1139 18.555 14.1139 18.55 14.1069 18.545C14.0999 18.54 14.0999 18.536 14.0969 18.532L11.5309 14.732H11.5239C11.4774 14.669 11.4185 14.6163 11.3509 14.577C11.2826 14.5376 11.2071 14.5124 11.1289 14.503V14.5H9.59287C9.50816 14.5064 9.42588 14.5313 9.35187 14.573C9.28614 14.61 9.22837 14.6596 9.18187 14.719C9.1124 14.8075 9.07124 14.9148 9.06374 15.027C9.05625 15.1392 9.08278 15.2511 9.13987 15.348C9.15398 15.3701 9.16968 15.3912 9.18687 15.411H9.17987L12.0379 19.711L12.0589 19.746C12.1414 19.8649 12.2656 19.9484 12.4069 19.98C12.5486 20.0121 12.6972 19.9903 12.8239 19.919C12.8539 19.9015 12.8823 19.8814 12.9089 19.859L12.9139 19.868L13.9739 19.268V19.26C14.0753 19.1795 14.1459 19.0665 14.1739 18.94C14.2007 18.8147 14.1833 18.684 14.1249 18.57L14.1219 18.574Z" fill="#1A1818" />
                </svg>
                꼭 읽어보세요.
              </h4>
              <ul>
                <li>일부 기능을 회사(수임업체)에서 직접 사용하는 경우 회사에서 요금이 발생할 수 있습니다.</li>
                <li>
                  일용직 잡급대장 사용은 현재 이벤트 기간으로 무료 사용 가능합니다.<br />
                  (무료 이벤트는 사전 공지 후 중단될 수 있어요.)
                </li>
                <li>매월 20일 결제됩니다.</li>
                <li>첫 결제일에는 일할계산되어 청구됩니다.</li>
                <li>부가세 미포함 가격입니다.</li>
              </ul>
            </div>
          </Flex>
          <Flex direction="column" alignItems="flex-start" rowGap={24} style={{ width: "100%" }}>
            <h4 className={styles.title}>예상 금액</h4>
            <div className={styles.price_check_container}>
              <div>
                <span>4대 보험료 산출•고지내역 설정</span>
                <MultiButton className={styles.button_wrap}>
                  <Button
                    width={100}
                    size="sm"
                    type={selectButton === "on" ? "primary" : "line-gray"}
                    onClick={() => {
                      setSelectButton('on');
                    }}
                  >
                    ON
                  </Button>
                  <Button
                    width={100}
                    size="sm"
                    type={selectButton === "off" ? "primary" : "line-gray"}
                    onClick={() => {
                      setSelectButton('off');
                    }}
                  >
                    OFF
                  </Button>
                </MultiButton>
              </div>
              <div>
                <span>수임업체 내 총 직원 수</span>
                <div>
                  <Input placeholder="입력" style={{ width: 135 }} controls={false} value={employeeCount} onChange={(evt) => setEmployeeCount(extractNumbers(evt))} />
                  <span>명</span>
                </div>
              </div>
              <div>
                <b>월 {monthPrice}원</b>
              </div>
            </div>
          </Flex>
          <Flex direction="column" alignItems="flex-start" rowGap={24} style={{ width: "100%", marginBottom: 120 }}>
            <h4 className={styles.title}>FAQ</h4>
            <Collapse
              items={items}
              className={styles.collapse_custom}
              expandIconPosition="end"
            />
          </Flex>
          {processModalOpen && <DisplayFeeReflectionProcessModal modalOpen={processModalOpen} modalClose={() => setProcessModalOpen(false)} />}
        </Flex>
      </div>
    </div>
  )
}