import { Modal } from 'antd'
import { Button, MultiButton } from 'components/button'
import { Flex } from 'components/flex'
import React from 'react'
import styles from "./modal.module.css"
import LinkImage from "assets/images/insurance_link.png"

function InsuranceLinkModal(props) {
  return (
    <Modal
      title={"4대 보험 연동 하기"}
      centered
      open={props.open}
      closeIcon={false}
      width={610}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={
        <MultiButton>
          <Button type="primary" onClick={props.handleOk} width={125} size="sm">
            연동하기
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125} size="sm">
            닫기
          </Button>
        </MultiButton>
      }
    >
      <div className={styles.link_container}>
        <Flex direction='column' alignItems='flex-start'>
          <h3>🔥 4대 보험 연동이란?</h3>
          <div>
            <p>수임 업체를 등록하기 위해 4대 보험을 연동해야해요.</p>
            <p>
              <b>자동으로 4대 보험 EDI에 노무 법입으로 로그인해서 수임 업체 목록을 가져올게요.</b>
            </p>
            <p>건강보험, 국민연금, 고용보험/산재보험 사이트에 로그인 하려면 공인 인증서가 필요해요.</p>
            <p>환경설정으로 이동하여 공인 인증서로 가져오기를 통해 4대 보험 연동을 진행해보세요.</p>
          </div>
        </Flex>
        <img src={LinkImage} alt="4대 보험 연동하기 가이드" width={530}/>
      </div>
    </Modal>
  )
}

export default InsuranceLinkModal