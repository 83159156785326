import createIcon from "components/icon/createIcon"

export const CheckWaitOffIcon = (props) => {
  return createIcon({
    size: props.size,
    path: <>
      <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" fill="#FFF2CD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 5.125C12.5753 5.125 13.0417 5.59137 13.0417 6.16667V11.7729L16.6325 13.5683C17.1471 13.8256 17.3556 14.4513 17.0984 14.9658C16.8411 15.4804 16.2154 15.689 15.7008 15.4317L11.5342 13.3484C11.1813 13.1719 10.9583 12.8112 10.9583 12.4167V6.16667C10.9583 5.59137 11.4247 5.125 12 5.125Z" fill="#FFCA21" />
    </>
  })
} 