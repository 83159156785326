import { Tab } from "components/hulam_platform"
import { useState } from "react"
import ProfileEditTab from "./tabs/ProfileEditTab"
import { snackBar } from "utils"
import InsuranceIntegrationTab from "./tabs/InsuranceIntegrationTab"
import { useAuth } from "AuthProvider"

export const HulamSetting = () => {
  const [toggleState, setToggleState] = useState({
    toggle1: false,
    toggle2: false,
    toggle3: false,
    toggle4: false,
  })

  const { author } = useAuth(); 

  const setToggle = (target, bool) => setToggleState({...toggleState, [target]: bool})

  /**@function updateProfile()
  * 프로필 수정
 */
  const updateProfile = () => {
    snackBar('프로필 정보가 수정되었습니다.')
  }

  const menuTitle = author === "manager" ? "환경 설정" : "계정 설정";
  

  const tabItems = () => {
    if (author === "manager") {
      return [
        {
          key: '1',
          label: '4대 보험 연동',
          children: <InsuranceIntegrationTab toggleState={toggleState} setToggle={setToggle}/>,
          forceRender: true
        },
        {
          key: '2',
          label: '프로필 수정',
          children: <ProfileEditTab confirm={updateProfile} />,
          forceRender: true
        }
      ]
    } else {
      return [{
          key: '1',
          label: '프로필 수정',
          children: <ProfileEditTab confirm={updateProfile} />,
          forceRender: true
        }
      ]
    }
  } 

  return (
    <div>
      <h2 style={{ fontSize: 24, fontWeight: "bold" }}>{menuTitle}</h2>
      <Tab items={tabItems()} style={{ marginTop: 40 }}/>
    </div>
  )
}