import { Modal } from 'antd'
import { Button } from 'components/button'
import { Flex } from 'components/flex'
import React from 'react'
import styles from "./displayFeeReflectionProcessModal.module.css"
import { Tooltip } from 'components/tooltip'
import tooltip2 from "assets/images/faq_2_1.jpg";

const ArrowDownIcon = () => {
  return <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 1C8.5 0.447715 8.05228 2.41411e-08 7.5 0C6.94772 -2.41411e-08 6.5 0.447715 6.5 1L8.5 1ZM6.79289 16.7071C7.18342 17.0976 7.81658 17.0976 8.20711 16.7071L14.5711 10.3431C14.9616 9.95262 14.9616 9.31946 14.5711 8.92893C14.1805 8.53841 13.5474 8.53841 13.1569 8.92893L7.5 14.5858L1.84315 8.92893C1.45262 8.53841 0.819456 8.53841 0.428932 8.92893C0.0384074 9.31946 0.0384074 9.95262 0.428932 10.3431L6.79289 16.7071ZM6.5 1L6.5 16L8.5 16L8.5 1L6.5 1Z" fill="#ACB1BA" />
  </svg>
}

const ArrowRightIcon = () => {
  return <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM1 9H16V7H1V9Z" fill="#ACB1BA" />
  </svg>
}


export default function DisplayFeeReflectionProcessModal(props) {

  return (
    <Modal
      title={"이용 요금 반영 과정"}
      open={props.modalOpen}
      closeIcon={false}
      width={765}
      height={775}
      footer={
        <Button type="secondary" size="sm" onClick={props.modalClose}>
          닫기
        </Button>
      }
      styles={{
        body: {
          padding: "20px",
          overflow: "scroll"
        }
      }}
    >
      <Flex direction='column'>
        <div className={styles.container}>
          <div className={styles.item_container}>
            <ol>
              <li className={styles.item}>업무 대행사<br />계정 가입</li>
              <li><ArrowRightIcon /></li>
              <li className={styles.item}>이용권 신청</li>
            </ol>
          </div>
          <ArrowDownIcon />
          <div className={styles.item_container}>
            <span className={styles.title_name}>휴램프로 사용</span>
            <ol className={styles.list_wrap}>
              <li className={styles.item}>4대 보험 EDI에서<br />수임업체 가져옴</li>
              <li><ArrowRightIcon /></li>
              <li className={styles.item}>
                수임업체에<br />4대 보험료<br />산출•고지내역<br />자동설정 ON/OFF
                <Tooltip
                  title={
                    <div style={{ display: "flex", flexDirection: 'column' }}>
                      <div className={styles.table_tr}>
                        <div className={styles.table_td} style={{ width: 80 }}>&nbsp;</div>
                        <div className={styles.table_td} style={{ width: 100 }}>비용</div>
                      </div>
                      <div className={styles.table_tr}>
                        <div className={styles.table_td} style={{ width: 80 }}>설정 ON</div>
                        <div className={styles.table_td} style={{ width: 100 }}>C명 X 300원</div>
                      </div>
                      <div className={styles.table_tr}>
                        <div className={styles.table_td} style={{ width: 80 }}>설정 OFF</div>
                        <div className={styles.table_td} style={{ width: 100 }}>C명 X 100원</div>
                      </div>
                    </div>
                  }
                  showIcon
                  color={"#fff"}
                  overlayInnerStyle={{
                    padding: 20,
                  }}
                />
              </li>
              <li><ArrowRightIcon /></li>
              <li className={styles.item}>수임업체 사업장에<br />직원 등록</li>
              <li><ArrowRightIcon /></li>
              <li className={styles.item} style={{ textAlign: "left" }}>사업장 관리<br />&nbsp;&nbsp;•급여대장<br />&nbsp;&nbsp;•근로계약서</li>
            </ol>
          </div>
          <div className={styles.item_container}>
            <span className={styles.title_name}>결제일</span>
            <ol>
              <li className={styles.item}>
                과금 대상<br />직원수 계산
                <Tooltip
                  className={styles.image_tooltip}
                  title={<><img src={tooltip2} alt='' width={520} /></>}
                  showIcon
                  color={"#fff"}
                  overlayInnerStyle={{
                    padding: 20,
                  }}
                />
              </li>
              <li><ArrowRightIcon /></li>
              <li className={styles.item}>후불 결제 청구</li>
            </ol>
          </div>
        </div>
      </Flex>
    </Modal>
  )
}
