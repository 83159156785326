import React, { useEffect, useState } from 'react'
import { Modal, Input, Form, Col, Row } from 'antd'
import { Button, Flex, NotificationCard, MultiButton } from 'components/hulam_platform'

import styles from './modal.module.css'
import { callApi, extractNumbers, formatManagerNumber, alert } from 'utils'
import { useAuth } from '../../../../AuthProvider'
import { ManageNumberItem } from '../../../../components/manageNumber-item';


const businessConfig = {
  rules: [
    {
      required: true,
      message: '사업자 등록 번호를 입력해 주세요.',
    },
  ],
}
const generateRandomUpperCase = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const length = 6

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    result += characters.charAt(randomIndex)
  }

  return result
}

const fields = [
  {
    name: 'companyName',
    value: ''
  },
  {
    name: 'businessNumber1',
    value: ''
  },
  {
    name: 'businessNumber2',
    value: ''
  },
  {
    name: 'businessNumber3',
    value: ''
  },
  {
    name: 'nps_mngt_no',
    value: ''
  },
  {
    name: 'nhis_mngt_no',
    value: ''
  },
  {
    name: 'employ_mngt_no',
    value: ''
  },
  {
    name: 'industrial_acc_mngt_no',
    value: ''
  }
]
export default function CompanyModifyModal (props) {
  const { author } = useAuth()
  const [data] = useState(props && props.data)
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState(fields);

  const modifyCompany = async (fieldsValue) => {
    let opId = window.sessionStorage.getItem('userId');

    const postData = {
      opId: opId,
      isInsuranceCompany: props && props.data && props.data.isInsuranceCompany,
      companyName: fieldsValue['companyName'],
      businessNumber: `${fieldsValue['businessNumber1']}${fieldsValue['businessNumber2']}${fieldsValue['businessNumber3']}`,
      originNumber: props && props.data && props.data.businessNum,
      mbId: props && props.data && props.data.mbId,
    }

    if (fieldsValue['nps_mngt_no'] && fieldsValue['nps_mngt_no'].replace(/-/g, "").length !== 11) {
      fieldsValue['nps_mngt_no'].scrollIntoView({behavior: 'smooth', block: 'center'});
      return;
    }

    if (fieldsValue['nhis_mngt_no'] && fieldsValue['nhis_mngt_no'].replace(/-/g, "").length !== 11) {
      fieldsValue['nhis_mngt_no'].scrollIntoView({behavior: 'smooth', block: 'center'});
      return;
    }

    if (fieldsValue['employ_mngt_no'] && fieldsValue['employ_mngt_no'].replace(/-/g, "").length !== 11) {
      fieldsValue['employ_mngt_no'].scrollIntoView({behavior: 'smooth', block: 'center'});
      return;
    }

    if (fieldsValue['industrial_acc_mngt_no'] && fieldsValue['industrial_acc_mngt_no'].replace(/-/g, "").length !== 11) {
      fieldsValue['industrial_acc_mngt_no'].scrollIntoView({behavior: 'smooth', block: 'center'});
      return;
    }

    postData.nps_mngt_no = fieldsValue['nps_mngt_no']?.replace(/-/g, "");
    postData.nhis_mngt_no = fieldsValue['nhis_mngt_no']?.replace(/-/g, "");
    postData.employ_mngt_no = fieldsValue['employ_mngt_no']?.replace(/-/g, "");
    postData.industrial_acc_mngt_no = fieldsValue['industrial_acc_mngt_no']?.replace(/-/g, "");


    let callAPIURL = ''

    if (author === 'employee') {
      postData.opcIdx = props.data && props.data.opcIdx
      postData.opeNo = sessionStorage.getItem('opeNo')

      callAPIURL = 'api/platform/modifyCompanyByEmp'
    } else {
      callAPIURL = 'api/platform/modifyCompany'
    }


    const response = await callApi(callAPIURL, postData)

    if (response.result) {
      props.handleOk('success')
    } else {
      if (response.msg) {
        alert({
          content: response.msg
        })
      }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    generateRandomUpperCase();

    const setData = fields.map(item => {
      // 사업자 등록번호 스플릿
      if (data.businessNum) {
        let businessNumber = data.businessNum.replaceAll('-', '')
        if (item.name === 'businessNumber1') {
          return { ...item, value: businessNumber?.substr(0, 3) }
        }

        if (item.name === 'businessNumber2') {
          return { ...item, value: businessNumber?.substr(3, 2) }
        }

        if (item.name === 'businessNumber3') {
          return { ...item, value: businessNumber?.substr(5, 5) }
        }
      }

      if (item.name === "nps_mngt_no") {
        return { ...item, value: props.npsMngtNo ? formatManagerNumber(props.npsMngtNo, 'no-input'): "" }
      }

      if (item.name === "nhis_mngt_no") {
        return { ...item, value: props.nhisMngtNo ? formatManagerNumber(props.nhisMngtNo, 'no-input'): "" }
      }

      if (item.name === "employ_mngt_no") {
        return { ...item, value: props.employMngtNo ? formatManagerNumber(props.employMngtNo, 'no-input') : "" }
      }

      if (item.name === "industrial_acc_mngt_no") {
        return { ...item, value: props.industrialAccMngtNo ? formatManagerNumber(props.industrialAccMngtNo, 'no-input') : ""}
      }


      if (data[item.name]) {
        return { ...item, value: data[item.name] }
      } else {
        return item
      }
    });

    setNewData(setData);
  }, [])


  if (data.isInsuranceCompany === 'Y') {
    return (
      <Modal
        centered
        title={'수임업체 이름 수정'}
        open={props.modalOpen}
        closeIcon={false}
        width={800}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        footer={
          <MultiButton>
            <Button
              onClick={() => {
                setIsLoading(true);
                form.submit()
              }}
              width={125}
              disabled={isLoading}
            >
              수정
            </Button>
            <Button type="secondary" onClick={props.handleCancel} width={125}>
              닫기
            </Button>
          </MultiButton>
        }
      >
        <div>
          <Form
            form={form}
            className={styles.form}
            fields={newData}
            onFinish={(value) => modifyCompany(value)}
            requiredMark={false}
            labelCol={{ flex: '120px' }}
            onFinishFailed={() => setIsLoading(false)}
          >
            <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
              <Col span={12}>
                <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <div style={{ width: 120 }}>회사 이름</div>
                  <Form.Item
                    name="companyName"
                    noStyle
                    required
                  >
                    <Input placeholder="입력" style={{ width: 180 }}/>
                  </Form.Item>
                </Flex>
              </Col>
            </Row>
            <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
              <Col span={24}>
                <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <ManageNumberItem title={"국민연금"} formName={"nps_mngt_no"}/>
                </Flex>
              </Col>
            </Row>
            <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
              <Col span={24}>
                <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <ManageNumberItem title={"건강보험"} formName={"nhis_mngt_no"}/>
                </Flex>
              </Col>
            </Row>
            <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
              <Col span={24}>
                <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <ManageNumberItem title={"고용보험"} formName={"employ_mngt_no"}/>
                </Flex>
              </Col>
            </Row>
            <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
              <Col span={24}>
                <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <ManageNumberItem title={"산재보험"} formName={"industrial_acc_mngt_no"}/>
                </Flex>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    )
  } else {

    return (
      <Modal
        centered
        title={'수임업체 수정'}
        open={props.modalOpen}
        closeIcon={false}
        width={800}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        footer={
          <MultiButton>
            <Button
              onClick={() => {
                setIsLoading(true);
                form.submit()
              }}
              width={125}
              isDisabled={isLoading}
            >
              수정
            </Button>
            <Button type="secondary" onClick={props.handleCancel} width={125}>
              닫기
            </Button>
          </MultiButton>
        }
      >
        <div>
          <NotificationCard size="small" message={<>4대 보험 연동을 통해 가져온 수임업체가 아닌 경우에만 사용해주세요.</>}/>
          <Form
            form={form}
            className={styles.form}
            fields={newData}
            onFinish={(value) => modifyCompany(value)}
            requiredMark={false}
            labelCol={{ flex: '120px' }}
            validateTrigger={"onBlur"}
            onFinishFailed={() => setIsLoading(false)}
          >
            <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
              <Col span={12}>
                <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <div style={{ width: 120 }}>회사 이름</div>
                  <Form.Item
                    name="companyName"
                    noStyle
                    required
                  >
                    <Input
                      placeholder="입력"
                      style={{ width: 180 }}
                      disabled={data && data.isHulamproAgree === 'Y'}
                    />
                  </Form.Item>
                </Flex>
              </Col>
            </Row>
            <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
              <Col span={24}>
                <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <div style={{ width: 120 }}>사업자 등록번호</div>
                  <Form.Item
                    noStyle
                    required
                  >
                    <Flex columnGap={10}>
                      <Form.Item name="businessNumber1" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                        <Input placeholder="입력" style={{ width: 130 }} maxLength={3} controls={false}/>
                      </Form.Item>
                      -
                      <Form.Item name="businessNumber2" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                        <Input placeholder="입력" style={{ width: 130 }} maxLength={2} controls={false}/>
                      </Form.Item>
                      -
                      <Form.Item name="businessNumber3" noStyle {...businessConfig} getValueFromEvent={extractNumbers}>
                        <Input placeholder="입력" style={{ width: 130 }} maxLength={5} controls={false}/>
                      </Form.Item>
                    </Flex>
                  </Form.Item>
                </Flex>
              </Col>
            </Row>
            {
              (data && data.isHulamproAgree === 'Y') && (
                <>
                  <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
                  <Col span={24}>
                    <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <ManageNumberItem title={"국민연금"} formName={"nps_mngt_no"}/>
                    </Flex>
                  </Col>
                </Row>
                  <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
                    <Col span={24}>
                      <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <ManageNumberItem title={"건강보험"} formName={"nhis_mngt_no"}/>
                      </Flex>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
                    <Col span={24}>
                      <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <ManageNumberItem title={"고용보험"} formName={"employ_mngt_no"}/>
                      </Flex>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
                    <Col span={24}>
                      <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <ManageNumberItem title={"산재보험"} formName={"industrial_acc_mngt_no"}/>
                      </Flex>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form>
        </div>
      </Modal>
    )
  }

}


