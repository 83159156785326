import { Tooltip as ATooltip } from 'antd'
import { HelpCircleGrayIcon } from 'components/icon'
import React from 'react'
import "./tooltip.scss"

export const Tooltip = (props) => {
  const {
    showIcon = false,
    icon,
    iconSize,
    children,
    ...rest
  } = props;

  return (
    <ATooltip
      {...rest}
    >
      {showIcon ?
        <div>
          {icon ? icon : <HelpCircleGrayIcon size={iconSize ? iconSize : 24}/>}
        </div> : ''
      }
      {children}
    </ATooltip>
  )
}