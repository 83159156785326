import createIcon from "components/icon/createIcon"

export const CheckCircleOnIcon = (props) => {
  return createIcon({
    size: props.size,
    path: <>
      <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" fill="#03CF5D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.8251 7.85953C17.2709 8.22319 17.3375 8.87937 16.9738 9.32515L10.9581 16.699L7.15615 13.4463C6.71901 13.0723 6.66782 12.4147 7.04182 11.9776C7.41582 11.5404 8.07337 11.4893 8.51051 11.8632L10.6919 13.7296L15.3595 8.00819C15.7232 7.56242 16.3794 7.49586 16.8251 7.85953Z" fill="#C6F8C5" />
    </>
  })
} 