import React from 'react'
import styles from "./Flex.module.css"

export function Flex({
  display = "flex",
  rowGap = 8,
  columnGap = 8,
  alignItems="center",
  justifyContent="flex-start",
  direction="row",
  className="",
  style,
  children
}) {
  return (
    <div 
      className={`${styles.flex} ${className}`}
      style={{
        display: display,
        rowGap: rowGap,
        columnGap: columnGap,
        alignItems: alignItems,
        justifyContent: justifyContent,
        flexDirection: direction,
        ...style
      }}
    >
      {children}
    </div>
  )
}
