import React from 'react'

export default function createIcon(props) {
  const {
    ref,
    size = 24,
    viewBox = "0 0 24 24",
    defaultProps = {},
    className,
    path = null
  } = props

  const defaultPath = <path fillRule="evenodd" clipRule="evenodd" d="M5.22781 5.22781C5.53155 4.92406 6.02401 4.92406 6.32775 5.22781L12 10.9001L17.6723 5.22781C17.976 4.92406 18.4685 4.92406 18.7722 5.22781C19.0759 5.53155 19.0759 6.02401 18.7722 6.32775L13.0999 12L18.7722 17.6723C19.0759 17.976 19.0759 18.4685 18.7722 18.7722C18.4685 19.0759 17.976 19.0759 17.6723 18.7722L12 13.0999L6.32775 18.7722C6.02401 19.0759 5.53155 19.0759 5.22781 18.7722C4.92406 18.4685 4.92406 17.976 5.22781 17.6723L10.9001 12L5.22781 6.32775C4.92406 6.02401 4.92406 5.53155 5.22781 5.22781Z" fill="#1A1818" />
  const iconStyle = {
    display: "inline-block",
  }

  return (
    <svg
      ref={ref}
      className={className}
      width={size}
      height={size}
      viewBox={viewBox}
      {...defaultProps}
      style={iconStyle}
    >
      {path ?? defaultPath}
    </svg>
  )
}
