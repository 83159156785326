import React from 'react'
import styles from "./Toggle.module.css"

export const Toggle = (props) => {
  const {
    defaultChecked = props.checked ? props.checked : true,
    htmlFor = 'toggle_id',
    className = props.className ? props.className : '', 
    style = props.style ? props.style : {},
    onChange,
    ...rest
  } = props

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  }
  return (
    <div className={`${styles.toggle} ${className}`}>
      <input 
        type="checkbox" 
        id={htmlFor}  
        checked={defaultChecked}
        style={style}
        onChange={handleChange}
        {...rest} 
      />
      <label htmlFor={htmlFor}></label>
    </div>
  )
}
