import styles from "./Checkbox.module.css";

export const CheckBox = (props) => {
  const {
    ref,
    isDisabled = false,
    children,
    type = "primary",
    size = "md",
    width,
    className,
    style,
    ...rest
  } = props

  const basicStyle = {
    ...style
  };

  return (
    <label 
      className={[`${styles.hulam_checkbox} ${styles[type]} ${styles[size]} ${className}`]} 
      style={basicStyle}
    > 
      <input type="checkbox" {...rest} disabled={isDisabled}/>
      {children}
    </label>
  )
}
