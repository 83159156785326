import { Modal, Table } from 'antd'
import { MultiButton, Button } from 'components/button'
import React, { useEffect, useState } from 'react'
import { callApi, alert, snackBar } from 'utils'
import { NotificationCard } from 'components/notification'
import { Flex } from 'components/hulam_platform'
import { useAuth } from '../../../../AuthProvider'

export default function AccountConnectModal (props) {
  const { author } = useAuth()
  const [sectionAccount, setSectionAccount] = useState({})

  const [data, setData] = useState([])
  const [isOther, setIsOther] = useState(false)

  const columns = [
    {
      title: '회사명',
      dataIndex: 'mbName',
      key: 'mbName',
      width: 210
    },
    {
      title: '아이디',
      dataIndex: 'mbId',
      key: 'mbId',
      width: 214
    },
    {
      title: '대표자명',
      dataIndex: 'mbCPresident',
      key: 'mbCPresident',
      width: 248
    },
    {
      title: '대표 이메일 주소',
      dataIndex: 'mbEmail',
      key: 'mbEmail',
      width: 200
    }
  ]

  const othercolumns = [
    {
      title: '회사명',
      dataIndex: 'mbName',
      key: 'mbName',
      width: 210
    },
    {
      title: '아이디',
      dataIndex: 'mbId',
      key: 'mbId',
      width: 214
    },
    {
      title: '연결 상태',
      dataIndex: 'isConnect',
      key: 'isConnect',
      width: 214,
      render: (text, record) => {

        let opId = sessionStorage.getItem('userId')
        if (record.opId === opId && record.isHulamproAgree === 'N') {
          return '연결 동의 대기중'
        } else if (record.opId === opId && record.isHulamproAgree === 'Y') {
          return '연결 완료'
        } else {
          return '연결 실패'
        }
      }
    },
    {
      title: '대표자명',
      dataIndex: 'mbCPresident',
      key: 'mbCPresident',
      width: 248
    },
    {
      title: '대표 이메일 주소',
      dataIndex: 'mbEmail',
      key: 'mbEmail',
      width: 200
    }
  ]

  useEffect(() => {
    getSelectList(props && props.data && props.data.businessNum, props && props.data && props.data.companyName)
  }, [props])

  const getSelectList = async (businessNum, companyName) => {
    let sessionStorage = window.sessionStorage
    let opId = sessionStorage.getItem('userId')

    const postData = {
      opId: opId,
      businessNum: businessNum,
      companyName: companyName,

    }

    const response = await callApi('api/platform/choiceCompanyList', postData)

    if (response && response.result) {

      if (response.other) {
        setIsOther(true)
      }
      setData(response.data.map((item, index) => ({ ...item, key: `${item.mbId}_${index}` })))
    }

  }

  const selectCompany = async () => {

    let sessionStorage = window.sessionStorage
    let opId = sessionStorage.getItem('userId')

    const postData = {
      opId: opId,
      businessNum: props && props.data && props.data.businessNum,
      mbId: sectionAccount.mbId,
    }

    let callAPIURL = ''

    if (author === 'employee') {
      postData.opcIdx = props.data && props.data.opcIdx
      postData.opeNo = sessionStorage.getItem('opeNo')

      callAPIURL = 'api/platform/choiceCompanyByEmp'
    } else {
      callAPIURL = 'api/platform/choiceCompany'
    }

    const response = await callApi(callAPIURL, postData)

    if (response) {
      if (response.result) {
        props.handleOk()
      } else {
        snackBar(response.msg)
      }

    } else {
      snackBar('연결이 실패 했습니다.')
    }
  }

  const makeCompany = async () => {

    alert({
      type: 'confirm',
      icon: true,
      content: <div>사업장 계정을 만드시겠어요?<br/>사업장 계정을 생성하면 자동으로 계정이 연결됩니다.</div>,
      onOk: async () => {

        let sessionStorage = window.sessionStorage
        let opId = sessionStorage.getItem('userId')

        const postData = {
          opId: opId,
          businessNum: props && props.data && props.data.businessNum,
          companyName: props && props.data && props.data.companyName,
          mbId: sectionAccount.mbId
        }

        let callAPIURL = ''

        if (author === 'employee') {
          postData.opcIdx = props.data && props.data.opcIdx
          postData.opeNo = sessionStorage.getItem('opeNo')

          callAPIURL = 'api/platform/makeCompanyByEmp'
        } else {
          callAPIURL = 'api/platform/makeCompany'
        }

        const response = await callApi(callAPIURL, postData)

        if (response && response.result) {

        }

        props.handleOk()
      }
    })

  }
  return (
    <Modal
      title={'사업장 계정 선택하기'}
      centered
      open={props.open}
      closeIcon={false}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={
        <MultiButton>
          <Button type="primary" onClick={() => {selectCompany()}} width={125}>
            확인
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
    >
      <div>
        {isOther &&
        (data && data.length === 0) ? null :
          <NotificationCard
            style={{
              marginBottom: 20
            }}
            message={
              isOther ?
                (data && data.length === 0 ? null : <>
                  ‘{props && props.data && props.data.companyName}’의 관리번호는 국세청에서 제공하는 사업자 등록번호가 아니에요.<br/>
                  연결된 휴램 계정 중 사업장명이 유사한 계정을 찾았어요.<br/>
                  연결하려는 휴램 계정을 선택해 주세요.<br/>
                  적합한 휴램 계정이 없다면 아래로 문의 주시면 빠르게 안내드리겠습니다.<br/>
                  help@hulampro.com
                </>) :
                <>
                  ‘{props && props.data && props.data.companyName}’가 사용하는 휴램 계정 아이디를 선택해 주세요.<br/>
                  ‘{props && props.data && props.data.companyName}'의 인사 담당자에게 확인해 주세요.<br/>
                  사업자 등록 번호를 통해 찾은 계정입니다.<br/>
                  하나의 사업자 등록 번호로 여러개의 계정을 사용하고 있을 수 있습니다.
                </>}
          />
        }

        <Table
          rowSelection={{
            type: 'radio',

            onSelect: (record) => {
              setSectionAccount(record)
            }
          }}
          pagination={{ pageSize: 5 }}
          columns={isOther ? othercolumns : columns}
          dataSource={data}
        />

        <NotificationCard
          style={{
            marginBottom: 20
          }}
          message={<Flex>
            <div style={{ lineHeight: '17px' }}>
              선택할 사업장 계정이 없는 경우에는 사업장 계정을 만들어서 연결해 보세요.<br/>
              사업장 계정을 생성하면 자동으로 계정이 연결돼요.

            </div>
            <Button
              style={{ marginLeft: 40 }}
              size="sm"
              type="line-gray"
              onClick={() => makeCompany()}
            >사업장 계정 만들기</Button>

          </Flex>}
        />
      </div>
    </Modal>
  )
}
