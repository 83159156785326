import createIcon from "components/icon/createIcon"

export const InfoRedIcon = (props) => {
  return createIcon({
    size: props.size,
    path: <>
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#FF6666"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 7.16667C12.4602 7.16667 12.8333 7.53976 12.8333 8V12C12.8333 12.4602 12.4602 12.8333 12 12.8333C11.5398 12.8333 11.1667 12.4602 11.1667 12V8C11.1667 7.53976 11.5398 7.16667 12 7.16667ZM11.1667 16C11.1667 15.5398 11.5398 15.1667 12 15.1667H12.01C12.4702 15.1667 12.8433 15.5398 12.8433 16C12.8433 16.4602 12.4702 16.8333 12.01 16.8333H12C11.5398 16.8333 11.1667 16.4602 11.1667 16Z" fill="white"/>
    </>
  })
} 
