import React from 'react'
import { Select } from 'antd'
import "./SelectBox.scss"
import { ChevronDownIcon } from 'components/icon'

export const SelectBox = (props) => {
  return (
    <Select
      {...props}
      popupClassName={props.mode === "multiple" ? "ant-select-item-multiple " + props.popupClassName : props.popupClassName}
      suffixIcon={<ChevronDownIcon size={20}/>}
      menuItemSelectedIcon={<></>}
    />
  )
}
