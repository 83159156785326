import { Input } from "antd"; 

export const InputCustom = (props) => {
   
 
 
  return (
    <Input
      {...props}
    />
  )
}
