import { useState, useEffect } from 'react';

function useDebounce(value, delay) {
  // 상태와 이벤트 핸들러를 정의합니다.
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // 주어진 딜레이 이후에 값을 갱신합니다. 기본 delay 500
    const handler = setTimeout(() => setDebouncedValue(value), delay || 500 );

    // cleanup 함수에서 clearTimeout을 호출하여 이전에 설정된 타이머를 제거합니다.
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;