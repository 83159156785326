import { Form, Input } from 'antd';
import { Flex } from './flex';
import { formatManagerNumber } from '../utils';
import React from 'react';

const manageNumberConfig = {
  rules: [
    {
      required: false,
      pattern: '^\\d{3}-\\d{2}-\\d{5,6}-\\d{1}$',
      message: '유효한 번호로 입력해주세요.'
    },
  ]
}

export const ManageNumberItem = ({ title, formName }) => {
  return (
    <>
      <div style={{ width: 120, flex: '0 0 auto' }}>{title} 관리번호</div>
      <Form.Item
        style={{flex: 1, marginBottom: 0}}
      >
        <Flex columnGap={10}>
          <Form.Item
            name={formName}
            noStyle
            {...manageNumberConfig}
            getValueFromEvent={formatManagerNumber}
          >
            <Input
              placeholder="입력"
              controls={false}
            />
          </Form.Item>
        </Flex>
      </Form.Item>
    </>
  )
}