import { useState } from 'react';
import { ConfigProvider, Table } from 'antd';
import { Button, Flex, SelectBox, SearchBox, MultiButton } from "components/hulam_platform"
import { BuildingIcon, DotsHorizontalIcon } from 'components/icon';
import EmployeeAddModal from './modal/EmployeeAddModal';

import AssignedClientsListModal from './modal/AssignedClientsListModal';
import { alert, callApi, snackBar } from 'utils';
import './HulamEmployee.css';
import EmployeeUpdateModal from './modal/EmployeeUpdateModal';
import useDebounce from 'hooks/useDebounce';
import { useQuery } from 'react-query';

export const HulamEmployee = () => {
  const [isAddEmployee, setIsAddEmployee] = useState(false);
  const [isUpdateEmployee, setIsUpdateEmployee] = useState(false);
  const [isClientList, setIsClientList] = useState(false);
  const [dataOne, setDataOne] = useState({});
  const [searchData, setSearchData] = useState({
    type: '',
    text: ''
  });
  const [rows, setRows] = useState(20);
  const debouncedSearchText = useDebounce(searchData.text);

  const employeeList = async () => {
    let sessionStorage = window.sessionStorage;
    let opId = sessionStorage.getItem("userId");

    const postData = {
      opId: opId,
      searchText: searchData.text,
      searchType: searchData.type
    };

    const response = await callApi('api/platform/employeeList', postData);

    if (response&&response.result) {
      return response.data.map((item, index) => ({ ...item, key: `${item.name}_${index}` }))
    }else{
      return [];
    }

  }

  const { isLoading, isError, data, error, refetch } = useQuery(`hulamEmployee/${debouncedSearchText}`, employeeList)


  const updateActive = async (email, mode) => {
    let opId = window.sessionStorage.getItem("userId");

    if(isError){
      console.log(error);
    }
    const postData = {
      opId: opId,
      employeeEmail: email,
      mode: mode === "active" ? "1" : "0"
      // 추가 필드
    };

    const response = await callApi('api/platform/updateActive', postData)
    if (response&&response.result) {
      //Alert("활성화가 완료 되었습니다.")
      refetch();
    } else {
      //Alert("비활성화가 완료 되었습니다.")
    }
  }

  const deleteEmployee = async (email) => {
    let opId = window.sessionStorage.getItem("userId");

    const postData = {
      opId: opId,
      employeeEmail: email
      // 추가 필드
    };
 

    const response = await callApi('api/platform/deleteEmployee', postData)
   
    if (response&&response.result) {
      //Alert("삭제가 완료 되었습니다.")
      refetch();
    } else {

    }
  }


  const columns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 160,
      sorter: (a, b) => a.name.localeCompare(b.name, 'ko'),
    },
    {
      title: '담당',
      dataIndex: 'role',
      key: 'role',
      width: 200,
      sorter: (a, b) => a.role.localeCompare(b.role, 'ko'),
    },
    {
      title: '이메일 주소',
      dataIndex: 'employeeEmail',
      key: 'employeeEmail',
      width: 220
    },
    {
      title: '휴대폰 번호',
      dataIndex: 'phone',
      key: 'phone',
      width: 170,
      sorter: (a, b) => a.phone.localeCompare(b.phone, 'ko'),
      render: (value) => `${value.substr(0,3)}-${value.substr(3,4)}-${value.substr(7,4)}`
    },
    {
      title: '계정상태',
      dataIndex: 'active',
      key: 'active',
      width: 115,
      sorter: (a, b) => a.active - b.active,
      render: (text, record) => {
        if (text === "1") {
          return <Flex columnGap={4}>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill="#03CF5D" />
            </svg>
            활성
          </Flex>
        } else {
          return <Flex columnGap={4}>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill="#ACB1BA" />
            </svg>
            비활성
          </Flex>
        }
      }
    },
    {
      title: '작업',
      dataIndex: 'work',
      key: 'work',
      render: (text, record) => {
        let selectList = [];
        if (record.active === "1") {
          selectList = [
            {
              key: "deactive", value: "deactive", label: '비활성화하기'
            },
            {
              key: "delete", value: "delete", label: '삭제하기'
            }
          ];
        } else {
          selectList = [
            {
              key: "active", value: "active", label: '활성화하기'
            },
            {
              key: "delete", value: "delete", label: '삭제하기'
            }
          ];

        }


        return (
          <MultiButton>
            <Button type="line-gray" size="sm" width={90} onClick={() => {
              setDataOne(record);
              setIsUpdateEmployee(true);
            }}>정보 수정</Button>
            <Button type="line-gray" size="sm" onClick={() => {
             
              setDataOne(record); 
              setIsClientList(true)
            }}>
              <BuildingIcon size={20} />
              {record&&record.companyCount}개
            </Button>
            <SelectBox
              value={<DotsHorizontalIcon size={20} />}
              placeholder={<DotsHorizontalIcon size={20} />}
              placement={"bottomRight"}
              style={{ width: 70 }}
              options={selectList}
              dropdownStyle={{
                width: 182
              }}
              onSelect={(e) => {
                if (e === "deactive") {
                  alert({
                    type: "confirm",
                    icon: true,
                    content: "계정이 ‘비활성화’되면 해당 직원 계정으로 로그인이 불가능해집니다. 비활성화하시겠습니까?",
                    onOk: () => {
                      updateActive(record.employeeEmail, "deactive");

                    }
                  })
                  return;
                }

                if (e === "active") {
                  alert({
                    type: "confirm",
                    icon: true,
                    content: "계정이 ‘활성화’되면 해당 직원 계정으로 로그인이 가능해집니다. 활성화하시겠습니까?",
                    onOk: () => {
                      updateActive(record.employeeEmail, "active");
                    }
                  })
                  return;
                }

                if (e === "delete") {
                  alert({
                    type: "confirm",
                    icon: true,
                    content: "해당 직원의 모든 정보가 삭제돼요. 계정 히스토리를 남기시면서 해당 직원 계정으로 로그인을 막으시려면 직원 계정을 ”비활성”해보세요. 직원 계정을 삭제하시겠습니까?",
                    onOk: () => {
                      deleteEmployee(record.employeeEmail);
                    }
                  })
                  return;
                }
              }}
            />
          </MultiButton>
        )
      },
    },
  ];

  const addEmployee = (status) => {
    if (status === "success") {
      refetch();
      snackBar("직원 등록이 완료되었습니다.");
    }

    setIsAddEmployee(false);
  }

  const updateEmployee = () => {
    refetch();
    snackBar("정보 수정이 완료되었습니다.");
    setIsUpdateEmployee(false);
  }

  const handleOk = () => {
    setIsClientList(false);
  }

  const handleCancel = () => {
    setIsAddEmployee(false);
    setIsClientList(false);
    setIsUpdateEmployee(false);
  }

  const customizeRenderEmpty = () => (
    <Flex
      justifyContent="center"
      style={{
        height: 178,
        background: "var(--gray-50-background)"
      }}
    >
      <Flex direction="column" rowGap={24}>
        내역이 없어요.
      </Flex>
    </Flex>
  );


  return (
    <div>
      <h2 style={{ fontSize: 24, fontWeight: "bold" }}>직원 관리</h2>
      <Flex style={{ marginTop: 30 }} justifyContent='space-between'>
        <Flex>
          <SelectBox
            defaultValue={searchData.type}
            popupMatchSelectWidth={false}
            value={searchData.type}
            style={{ width: '118px' }}
            onChange={e => setSearchData({ ...searchData, type: e })}
            options={[
              {
                value: "", label: "선택"
              },
              {
                value: "companyName", label: "수임 업체명"
              },
              {
                value: "employeeName", label: "담당 직원명"
              },
              {
                value: "employeeEmail", label: "담당 직원 이메일 주소"
              }
            ]}
          />
          <SearchBox
            placeholder="입력"
            width={204}
            value={searchData.text}
            onChange={e => setSearchData({ ...searchData, text: e.target.value })}
            onClick={refetch}
          />
          <SelectBox
            defaultValue={rows}
            value={rows}
            style={{ width: '118px' }}
            suffixIcon={<i className='svg_icon_16_search_arrow_down'></i>}
            onChange={(value) => setRows(value)}
            options={[
              {
                value: 10, label: "10줄씩 보기"
              },
              {
                value: 20, label: "20줄씩 보기"
              },
              {
                value: 30, label: "30줄씩 보기"
              },
              {
                value: 50, label: "50줄씩 보기"
              },
              {
                value: 100, label: "100줄씩 보기"
              }
            ]}
          />
        </Flex>
        <Button
          onClick={() => {
            setIsAddEmployee(true);
          }}
          width={158}
        >
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 0.75C7.41421 0.75 7.75 1.08579 7.75 1.5V6.75H13C13.4142 6.75 13.75 7.08579 13.75 7.5C13.75 7.91421 13.4142 8.25 13 8.25H7.75V13.5C7.75 13.9142 7.41421 14.25 7 14.25C6.58579 14.25 6.25 13.9142 6.25 13.5V8.25H1C0.585786 8.25 0.25 7.91421 0.25 7.5C0.25 7.08579 0.585786 6.75 1 6.75H6.25V1.5C6.25 1.08579 6.58579 0.75 7 0.75Z" fill="white" />
          </svg>
          직원 등록
        </Button>
      </Flex>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Table
          columns={columns}
          dataSource={data}
          style={{ marginTop: 110 }}
          loading={isLoading}
          pagination={{
            pageSize: rows
          }}
        />
      </ConfigProvider>
      {isAddEmployee && <EmployeeAddModal modalOpen={isAddEmployee} handleOk={addEmployee} handleCancel={handleCancel} />}
      {isUpdateEmployee && <EmployeeUpdateModal data={dataOne} modalOpen={isUpdateEmployee} handleOk={updateEmployee} handleCancel={handleCancel} />}
      {isClientList && <AssignedClientsListModal  data={dataOne}  modalOpen={isClientList} handleOk={handleOk} handleCancel={handleCancel} refetch={refetch} />}
    </div>
  )
}