import { Modal, Table } from 'antd'
import { MultiButton, Button } from 'components/button'
import React, { useState } from 'react'
import { NotificationCard } from 'components/notification'
import { Flex } from 'components/flex';
import { callApi, snackBar } from 'utils';
import styles from "./modal.module.css"
import { useQuery } from 'react-query';

export default function ModifyLinkedAccountModal(props) {
  const [sectionAccount, setSectionAccount] = useState({
    key: '',
    companyName: '',
    connectedAccount: '', //기존 연결된 사업장 계정
    modifyAccount: '',
  });

  const columns = [
    {
      title: '회사명',
      dataIndex: 'mbName',
      key: 'mbName',
      width: 210
    },
    {
      title: '아이디',
      dataIndex: 'mbId',
      key: 'mbId',
      width: 214
    },
    {
      title: '대표자명',
      dataIndex: 'mbCPresident',
      key: 'mbCPresident',
      width: 248
    },
    {
      title: '대표 이메일 주소',
      dataIndex: 'mbEmail',
      key: 'mbEmail',
      width: 200
    }
  ];

  const getSelectList = async (businessNum, companyName) => {
    let sessionStorage = window.sessionStorage;
    let opId = sessionStorage.getItem("userId");

    const postData = {
      opId: opId,
      businessNum: businessNum,
      companyName: companyName
    };

    const response = await callApi('api/platform/connectCompanyList', postData);

    if (response && response.result) {
      const newData = response.data.map((item, index) => ({ ...item, key: `${item.mbId}_${index}` }));
      return newData;
    }

  }

  const { isLoading, data } = useQuery(`linkedAccount`, () => getSelectList(props.data.businessNum, props.data.companyName))

  const selectCompany = async () => {

    let sessionStorage = window.sessionStorage;
    let opId = sessionStorage.getItem("userId");

    const postData = {
      opId: opId,
      businessNum: props && props.data && props.data.businessNum,
      mbId: sectionAccount.mbId
    };

    const response = await callApi('api/platform/choiceCompany', postData);

    if (response) {

      if (response.result) {

        props.handleOk();
      } else {
        snackBar(response.msg);
      }

    } else {
      snackBar("연결이 실패 했습니다.");
    }
  }

  return (
    <Modal
      title={"연결된 휴램 계정 변경하기"}
      open={props.open}
      centered
      closeIcon={false}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={
        <MultiButton>
          <Button type="primary" onClick={() => { selectCompany() }} width={125}>
            확인
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
      styles={{
        body: {
          overflowY: "scroll"
        }
      }}
    >
      <div>
        <NotificationCard
          style={{
            marginBottom: 20
          }}
          message={<>
            수임업체가 동일한데 연결된 사업장 계정이 2개 이상일 때 사용해 주세요.<br />
            보험 종류별 수임업체의 사업장 관리번호가 다른 경우 사업장 계정이 1개 이상이 만들어질 수 있어 하나로 변경이 필요해요.<br />
            예시: 건강보험의 수임업체인 '(주)제임스웹'과 연결된 사업장 계정이 james이고 고용보험의 수임업체인 '(주)제임스웹'에<br />
            연결된 사업장 계정이 telescope라면 하나의 사업장 계정을 사용하도록 변경이 필요해요.
          </>}
        />
        <Flex direction='column' alignItems='flex-start' style={{ marginBottom: 20 }}>
          <h3 className={styles.linked_wrap_title}>선택한 계정</h3>
          <div className={styles.linked_wrap}>
            <Flex columnGap={10}>
              <strong>수임업체명</strong>
              <span>{props && props.data && props.data.companyName ? props.data.companyName : ""}</span>
            </Flex>
            <Flex columnGap={10}>
              <strong>연결된 사업장 계정</strong>
              <span>{props && props.data && props.data.mbId ? props.data.mbId : ""}</span>
            </Flex>
            <span>
              <svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.58 5.5C10.78 5.07333 10.9733 4.7 11.16 4.38C11.36 4.06 11.5533 3.79333 11.74 3.58H0.0800003V2.74H11.74C11.5533 2.51333 11.36 2.24 11.16 1.92C10.9733 1.6 10.78 1.23333 10.58 0.82H11.28C12.12 1.79333 13 2.51333 13.92 2.98V3.34C13 3.79333 12.12 4.51333 11.28 5.5H10.58Z" fill="#1A1818" />
              </svg>
            </span>
            <Flex columnGap={10}>
              <strong>변경할 사업장 계정</strong>
              <span>{sectionAccount.mbId ?? ""}</span>
            </Flex>
          </div>
        </Flex>
        <Table
          rowSelection={{
            type: "radio",
            onSelect: (record) => setSectionAccount(record)
          }}
          columns={columns}
          dataSource={data}
          loading={isLoading}
          style={{
            height: 300
          }}
        />
      </div>
    </Modal>
  )
}
