import createIcon from "components/icon/createIcon"

export const CheckReturnOffIcon = (props) => {
  return createIcon({
    size: props.size,
    path: <>
      <path d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z" fill="#FFD7D7" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.625 12C7.625 11.4247 8.09137 10.9583 8.66667 10.9583H15.3333C15.9086 10.9583 16.375 11.4247 16.375 12C16.375 12.5753 15.9086 13.0417 15.3333 13.0417H8.66667C8.09137 13.0417 7.625 12.5753 7.625 12Z" fill="#FF4E4E" />
    </>
  })
} 