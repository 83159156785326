export const CheckPrimaryIcon = (props) => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2425_17050)">
      <path d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z" fill="url(#paint0_linear_2425_17050)" />
      <path d="M7 12.7857L10.59 15.8571L17 8" stroke="white" strokeWidth="2.5" strokeLinecap="round" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2425_17050" x1="0" y1="0" x2="24.2549" y2="21.6402" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3478D1" />
        <stop offset="1" stopColor="#A67BED" />
      </linearGradient>
      <clipPath id="clip0_2425_17050">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
} 
