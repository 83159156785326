import React, { useEffect, useState } from 'react';
import { Modal, Input, Form, Row, Col, Upload, message, Radio } from 'antd'
import { callApi, callMultiApi, handlerEventLog, snackBar } from 'utils'
import { MultiButton, Button } from 'components/button'
import { useAuth } from 'AuthProvider'
import { UpOutlined, DownOutlined } from '@ant-design/icons'

import stroke from 'assets/images/stroke.png'
import { InfoGrayIcon } from 'components/icon'
import { Flex } from 'components/flex'
import { useLocation, useNavigate } from 'react-router-dom'

const DeleteIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.35683 4.35659C4.60995 4.10347 5.02033 4.10347 5.27345 4.35659L10.0003 9.08346L14.7272 4.35659C14.9803 4.10347 15.3907 4.10347 15.6438 4.35659C15.8969 4.6097 15.8969 5.02009 15.6438 5.27321L10.9169 10.0001L15.6438 14.727C15.8969 14.9801 15.8969 15.3905 15.6438 15.6436C15.3907 15.8967 14.9803 15.8967 14.7272 15.6436L10.0003 10.9167L5.27345 15.6436C5.02033 15.8967 4.60995 15.8967 4.35683 15.6436C4.10371 15.3905 4.10371 14.9801 4.35683 14.727L9.08371 10.0001L4.35683 5.27321C4.10371 5.02009 4.10371 4.6097 4.35683 4.35659Z"
            fill="#ACB1BA"/>
    </svg>
  )
}

const tempPassword = "defaultPassword";

export default function PublicCertImportModal (props) {
  const location = useLocation()
  const naviagte = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { author, companyName: opCompanyName } = useAuth()
  const [derFile, setDerFile] = useState(null)
  const [keyFile, setKeyFile] = useState(null)
  const [idx, setIdx] = useState(props && props.idx ? props.idx : '')
  const [derRealFile, setDerRealFile] = useState(props && props.derRealFile)
  const [keyRealFile, setKeyRealFile] = useState(props && props.keyRealFile)
  const [profileFields, setProfileFields] = useState([
    {
      name: 'companyName',
      value: ''
    },
    {
      name: 'presidentName',
      value: ''
    },
    {
      name: 'businessNumber',
      value: ''
    },
    {
      name: 'phoneNumber1',
      value: ''
    },
    {
      name: 'phoneNumber2',
      value: ''
    },
    {
      name: 'phoneNumber3',
      value: ''
    },
    {
      name: 'email',
      value: ''
    },
    {
      name: 'email1',
      value: ''
    },

  ])

  const [hovered, setHovered] = useState(false)
  const [click, setClick] = useState(false)
  const [value, setValue] = useState('2')
  const [juminNumber, setJuminNumber] = useState('')
  const [kcomwelPassword, setKcomwelPassword] = useState(props.update ? tempPassword : '')
  const [kcomwelDer, setKcomwelder] = useState('')
  const [kcomwelkey, setKcomwelKey] = useState('')
  const [kcomwelDerRealFile, setKcomwelDerRealFile] = useState(props && props.kcomwelDerRealFile)
  const [kcomwelKeyRealFile, setKcomwelKeyRealFile] = useState(props && props.kcomwelKeyRealFile)

  const opId = sessionStorage.getItem('userId')
  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }
  const handleOpen = () => {
    setClick(!click)
  }

  const getCertFile = async () => {
    let sessionStorage = window.sessionStorage
    let opId = sessionStorage.getItem('userId')
    if (opId) {
      const postData = {
        opId: opId
      }

      const response = await callApi('api/platform/getCertFile', postData)

      if (response && response.result) {

        if (response.derFile) {
          setDerRealFile(response.derFile)
        }

        if (response.keyFile) {
          setKeyRealFile(response.keyFile)
        }

        if (response.kcomwelDerFile) {
          setKcomwelDerRealFile(response.kcomwelDerFile)
        }

        if (response.kcomwelKeyFile) {
          setKcomwelKeyRealFile(response.kcomwelKeyFile)
        }

        if (response.idx) {
          setIdx(response.setIdx)
        }

        if (response.kcomwelLoginType) {
          setValue(response.kcomwelLoginType ? response.kcomwelLoginType : 2)
        }
        if (response.juminNumber) {
          let formattedValue = ''
          let juminNumber = (response.juminNumber) ? (response.juminNumber.replaceAll('-', '')) : ''

          if (juminNumber.length > 6) {
            formattedValue = `${juminNumber.slice(0, 6)}-${juminNumber.slice(6, 13)}`
          }

          setJuminNumber(formattedValue)

        }

      }
    }
  }

  const [form] = Form.useForm();

  const pollingCheckInsurance = async () => {
    const firstCheck = await companyStatus()

    if (~~firstCheck > 0) {

      snackBar(<>국민연금 EDI에서 수임업체를 불러오는 중이에요. (1/3)<br/>최대 30초 소요될 수 있어요.</>, { type: 'loading', duration: 30 })

      let step = 1
      const stepTimer = setInterval(() => {
        step++
        if (step === 2) {
          message.destroy()
          snackBar(<>건강보험 EDI에서 수임업체를 불러오는 중이에요. (2/3)<br/>최대 30초 소요될 수 있어요.</>, { type: 'loading', duration: 30 })
        }
        if (step === 3) {
          message.destroy()
          snackBar(<>고용산재 토탈서비스에서 수임업체를 불러오는 중이에요. (3/3)<br/>최대 1분 소요될 수 있어요.</>, { type: 'loading', duration: 300 })
        }
      }, 30000)

      const pollTimer = setInterval(() => {
        companyStatus().then((result) => {

          if (~~result > 0) {
          } else {
            const eventLog = {
              href: window.location.href,
              event_type: 'initial_fetch_firms_result',
              userId: opId,
              category: 'onboard',
              message: 'success',
              source: '',
              label1: '',
              label2: '',
              slack_message: `[수임업체 최초 등록 시도 결과]\n업무 대행사:${opCompanyName} (${opId})\n결과:성공`,
              slack_type: 'onborading'
            }

            handlerEventLog(eventLog)

            message.destroy()

            snackBar(<>연동이 완료되었습니다.</>)
            clearInterval(pollTimer) // 원하는 조건 충족 시 폴링 중지
            clearInterval(stepTimer)
            naviagte(0)
          }
        }).catch((error) => {
          // 에러 처리
          const eventLog = {
            href: window.location.href,
            event_type: 'initial_fetch_firms_result',
            userId: opId,
            category: 'onboard',
            message: 'fail',
            source: '',
            label1: '',
            label2: '',
            slack_message: `[수임업체 최초 등록 시도 결과]\n업무 대행사:${opCompanyName} (${opId})\n결과:실패`,
            slack_type: 'onborading'
          }

          handlerEventLog(eventLog)

          snackBar(<>연동에 실패했어요.</>)

          clearInterval(pollTimer) // 에러 발생 시 폴링 중지
          clearInterval(stepTimer)
          naviagte(0)
        })

      }, 5000)
    }
  }

  const companyStatus = async () => {
    //cert 등록 여부 
    let sessionStorage = window.sessionStorage
    let opId = sessionStorage.getItem('userId')

    const postData = {
      opId: opId
    }
    const response = await callApi('api/platform/loadStatus', postData)

    if (response.result) {
      return response.batchCnt
    }
  }

  const linkData = async (data) => {
    setIsLoading(true)

    let form = new FormData()
    form.append('businessNumber', data.businessNumber)
    form.append('password', data.password)
    form.append('der', derFile)
    form.append('key', keyFile)
    form.append('kcomwel_password', kcomwelPassword)
    form.append('kcomwel_der', kcomwelDer)
    form.append('kcomwel_key', kcomwelkey)

    form.append('juminNumber', juminNumber.replaceAll('-', ''))
    form.append('kcomwelLoginType', value)

    form.append('opId', opId)

    if (props.update) {
      if ((derFile && keyFile) || (derRealFile && keyFile) || (derFile && keyRealFile) || (derRealFile && keyRealFile)) {
        if (keyRealFile) {
          form.append('keyRealFile', keyRealFile)
        }
        if (derRealFile) {
          form.append('derRealFile', derRealFile)
        }
        if (kcomwelDerRealFile) {
          form.append('kcomwelDerRealFile', kcomwelDerRealFile)
        }
        if (kcomwelKeyRealFile) {
          form.append('kcomwelKeyRealFile', kcomwelKeyRealFile)
        }

        if (data.password === tempPassword || data.password === undefined) {
          form.delete("password");
        }

        if (kcomwelPassword === tempPassword) {
          form.delete("kcomwel_password");
        }

        const response = await callMultiApi('api/platform/certModify', form)

        if (response.result) {
          if (response.error) {
            snackBar('수임 업체 키 등록 및 연동에 실패했습니다. 비밀번호와 키 정보를 확인해주세요.')

            props.handlePwError()

          } else {
            pollingCheckInsurance()
            // snackBar('수임 업체 키 등록 및 연동이 완료 되었습니다.');
            props.handleOk()
          }

        } else {
          snackBar('수임 업체 키 등록 및 연동에 실패했습니다.')
        }

        setIsLoading(false)
      } else {
        snackBar('공인 인증서 파일 등록은 필수 입니다.')
      }

    } else {
      const response = await callMultiApi('api/platform/certUpload', form)
      if (response.result) {
        if (response.error) {
          snackBar('수임 업체 키 등록 및 연동에 실패했습니다. 비밀번호와 키 정보를 확인해주세요.')

          props.handlePwError()
        } else {

          let source = ''

          if (location.pathname === '/hulamSetting') {
            source = 'setting'
          } else if (location.pathname === '/hulamCompanyList') {
            source = 'firm list'
          }

          //공인 인증서 최초 등록 이벤트 로그 
          const eventLog = {
            href: window.location.href,
            event_type: 'initial_register_cert',
            userId: opId,
            category: 'onboard',
            message: '',
            source: source,
            label1: '',
            label2: '',
            slack_message: `[공인인증서 최초 등록]\n업무 대행사:${opCompanyName} (${opId})`,
            slack_type: 'onborading'
          }

          await handlerEventLog(eventLog)

          //수임업체 최초 등록 이벤트 로그 
          const eventLog2 = {
            href: window.location.href,
            event_type: 'initial_fetch_firms',
            userId: opId,
            category: 'onboard',
            message: '',
            source: '',
            label1: '',
            label2: '',
            slack_message: `[수임업체 최초 등록 시도]\n업무 대행사:${opCompanyName} (${opId})`,
            slack_type: 'onborading'
          }

          await handlerEventLog(eventLog2)

          pollingCheckInsurance()
          // snackBar('수임 업체 키 등록 및 연동이 완료 되었습니다.');
          props.handleOk()
        }

        setIsLoading(false)
      } else {
        snackBar('수임 업체 키 등록 및 연동에 실패했습니다.')
      }
    }

  }

  const profileSetting = async () => {
    let sessionStorage = window.sessionStorage
    let opId = sessionStorage.getItem('userId')

    const postData = {
      opId: opId
    }

    const response = await callApi('api/platform/viewPrivateSetting', postData)

    if (response && response.result) {
      const { data } = response

      if (data) {
        setProfileFields(profileFields.map(item => {
          if (data[item.name]) {
            return { ...item, value: data[item.name] }
          } else {
            return item
          }
        }))
      }
    }
  }

  const onChange = e => {
    setValue(e.target.value)
  }

  useEffect(() => {
    getCertFile()
  }, [])

  useEffect(() => {
    if (author === 'manager') {
      profileSetting()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      title={props.update ? '정보 수정' : '공인 인증서로 가져오기'}
      centered
      open={props.open}
      closeIcon={false}
      width={800}
      onCancel={props.handleCancel}
      styles={{
        body: { backgroundColor: 'white', overflowY: 'auto' }
      }}
      footer={
        <MultiButton>
          <Button
            type="primary"
            onClick={() => form.submit()}
            width={125}
            isDisabled={isLoading}
          >
            {props.update ? '수정' : '등록'}
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
    >
      <Form
        style={{ background: 'white', padding: 10, }}
        scrollToFirstError
        form={form}
        fields={profileFields}
        requiredMark={false}
        labelCol={{ flex: '120px' }}
        onFinish={(value) => linkData(value)}
      >
        <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
          <Col span={24}>
            <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
              <div style={{ width: 120, flex: '0 0 auto' }}>사업자 등록 번호</div>
              <Form.Item
                name={'businessNumber'}
                noStyle
              >
                <Input readOnly bordered={false}/>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
          <Col span={24}>
            <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
              <div style={{ width: 120, flex: '0 0 auto' }}>공인인증서<br/>비밀번호</div>
              <Form.Item
                name={'password'}
                noStyle
                rules={[
                  {
                    required: !props.update,
                  },
                ]}
              >
                <Input.Password
                  placeholder="입력"
                  visibilityToggle={false}
                  defaultValue={props.update ? tempPassword : ''}
                  onFocus={(e) => {
                    if (props.update && e.target.value === tempPassword) {
                      form.setFieldValue('password', '');
                    }
                  }}
                  onBlur={(e) => {
                    if (props.update && e.target.value === "") {
                      form.setFieldValue('password', tempPassword);
                    }
                  }}
                />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
          <Col span={24}>
            <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
              <div style={{ width: 120, flex: '0 0 auto' }}>공인인증서<br/>signCert 파일</div>
              <Form.Item
                name={'upload_der'}
                valuePropName="fileList"
                noStyle
                rules={[
                  {
                    required: !props.update,
                  },
                ]}
                getValueFromEvent={(e) => e?.fileList}
              >
                <Upload
                  name="der"

                  defaultFileList={props.update && derRealFile ? [{
                    uid: idx + 'der',
                    name: derRealFile,
                    status: 'done',
                  }] : []}
                  beforeUpload={(file) => {
                    setDerRealFile(null)
                    setDerFile(file)
                    return false
                  }}
                  onRemove={(res) => {
                    setDerRealFile(null)
                  }}
                  showUploadList={{
                    downloadIcon: <></>,
                    removeIcon: <DeleteIcon/>,
                  }}
                  maxCount={1}
                >
                  <Button size="sm">파일 업로드</Button>
                </Upload>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24}>
            <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
              <div style={{ width: 120, flex: '0 0 auto' }}>공인인증서<br/>signPri 파일</div>
              <Form.Item
                name={'upload_key'}
                valuePropName="fileList"
                noStyle
                rules={[
                  {
                    required: props.update ? false : true,
                  },
                ]}
                getValueFromEvent={(e) => e?.fileList}
              >
                <Upload
                  name="key"
                  defaultFileList={(props.update && keyRealFile) ? [{
                    uid: idx + 'key',
                    name: keyRealFile,
                    status: 'done',
                  }] : []}
                  beforeUpload={(file) => {

                    setKeyRealFile(null)
                    setKeyFile(file)
                    return false
                  }}
                  onRemove={(res) => {
                    setKeyRealFile(null)
                  }}
                  showUploadList={{
                    downloadIcon: <></>,
                    removeIcon: <DeleteIcon/>,
                  }}
                  maxCount={1}
                >
                  <Button size="sm">파일 업로드</Button>
                </Upload>
              </Form.Item>
            </Flex>
          </Col>
        </Row>
      </Form>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #E2E5EA',
        background: 'white',
        height: 130,
        borderRadius: 10,
        marginTop: 15
      }}>
        <div
          style={{ width: 70, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <InfoGrayIcon size={24}/>
        </div>
        <div style={{ width: '100%' }}>
          <span style={{ color: 'var(--gray-700---text, #52545C)', fontWeight: '700' }}>공인인증서 파일 찾는 방법</span><br/>
          <div style={{ marginTop: 5 }}>
            <img alt="meaningful_description" src="/sign1.png" style={{ height: 20 }}/>
            <img alt="meaningful_description" src="/sign2.png" style={{ height: 20 }}/>
          </div>
          <span style={{ color: 'var(--gray-700---text, #52545C)', fontSize: 14, fontWeight: 400, lineHeight: '20px' }}>
            공인인증서 파일을 usb에 보관하고 계시다면 해당 usb 안에서 signCert.der 파일과<br/>
            signPri.key 파일을 찾으실 수 있어요. 찾기 어려우시면 파일 검색을 시도해 보세요.
          </span>
        </div>
        <div style={{ width: 144 }}>
          <Button
            type="line-gray"
            onClick={() => {
              window.open('https://shimmer-vein-8d5.notion.site/9f9693eeab5b478b9c4a49ea181540e5')
            }}
            size="sm"
          >자세히 보기</Button>
        </div>
      </div>
      <div>
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleOpen}
          style={
            hovered ? {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 275,
                height: 32,
                padding: '4px 10px',
                backgroundColor: '#F3F4F8',
                borderRadius: 6,
                cursor: 'pointer',
                color: '#1A1818',
                fontSize: 18,
                fontWeight: '700',
                marginTop: 20
              } :
              {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 275,
                height: 32,
                padding: '4px 10px',
                color: '#1A1818',
                fontSize: 18,
                fontWeight: '700',
                marginTop: 20
              }}
        >
          고용・산재보험 토탈서비스 로그인 &nbsp;&nbsp; {click ? <UpOutlined/> : <DownOutlined/>}
        </div>
        {
          click ? (
            <Form
              style={{ background: 'white', padding: 10, }}
            >
              <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
                <Col span={24}>
                  <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ width: 120, flex: '0 0 auto' }}>공인인증서<br/>비밀번호</div>
                    <Form.Item
                      noStyle
                    >
                      <Input.Password
                        value={kcomwelPassword}
                        onFocus={(e) => {
                          if (props.update && e.target.value === tempPassword) {
                            setKcomwelPassword("")
                          }
                        }}
                        onBlur={(e) => {
                          if (props.update && e.target.value === "") {
                            setKcomwelPassword(tempPassword)
                          }
                        }}
                        onChange={e => {
                            setKcomwelPassword(e.target.value)
                        }}
                        visibilityToggle={false}
                        placeholder="입력"
                      />
                    </Form.Item>
                  </Flex>
                </Col>
              </Row>
              <Row gutter={24} style={{ borderBottom: '1px solid #E2E5EA' }}>
                <Col span={24}>
                  <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ width: 120, flex: '0 0 auto' }}>공인인증서<br/>signCert 파일</div>
                    <Form.Item
                      valuePropName="fileList"
                      noStyle
                      getValueFromEvent={(e) => e?.fileList}
                    >
                      <Upload
                        defaultFileList={props.update && kcomwelDerRealFile ? [{
                          uid: idx + 'der',
                          name: kcomwelDerRealFile,
                          status: 'done',
                        }] : []}
                        beforeUpload={(file) => {
                          setKcomwelDerRealFile(null)
                          setKcomwelder(file)
                          return false
                        }}
                        onRemove={(res) => {
                          setKcomwelDerRealFile(null)
                        }}
                        showUploadList={{
                          downloadIcon: <></>,
                          removeIcon: <DeleteIcon/>,
                        }}
                        maxCount={1}
                      >
                        <Button size="sm">파일 업로드</Button>
                      </Upload>
                    </Form.Item>
                  </Flex>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={24}>
                  <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ width: 120, flex: '0 0 auto' }}>공인인증서<br/>signPri 파일</div>
                    <Form.Item
                      valuePropName="fileList"
                      noStyle
                      getValueFromEvent={(e) => e?.fileList}
                    >
                      <Upload
                        defaultFileList={(props.update && kcomwelKeyRealFile) ? [{
                          uid: idx + 'key',
                          name: kcomwelKeyRealFile,
                          status: 'done',
                        }] : []}
                        beforeUpload={(file) => {
                          setKcomwelKeyRealFile(null)
                          setKcomwelKey(file)
                          return false
                        }}
                        onRemove={(res) => {
                          setKcomwelKeyRealFile(null)
                        }}
                        showUploadList={{
                          downloadIcon: <></>,
                          removeIcon: <DeleteIcon/>,
                        }}
                        maxCount={1}
                      >
                        <Button size="sm">파일 업로드</Button>
                      </Upload>
                    </Form.Item>
                  </Flex>
                </Col>
              </Row>
            </Form>
          ) : ''
        }
        <div
          style={{ padding: '4px 10px', color: '#52545C', fontSize: 14 }}>
          고용・산재보험 토탈서비스에 로그인하는 방법을 선택할 수 있어요.
        </div>
        {click ?
          <div style={{ padding: '4px 10px' }}>
            <div style={{
              width: 300,
              borderRadius: '4px',
              padding: '10px',
              backgroundColor: '#fff'
            }}>
              <Radio.Group onChange={onChange} value={value} style={{ display: 'flex' }}>
                <Radio value={'2'} style={{ flex: 1 }}>사업장명의 인증서</Radio>
                <Radio value={'0'} style={{ flex: 1 }}>대표자/소속직원</Radio>
              </Radio.Group>
            </div>
            <div style={{
              width: '100%',
              height: 1,
              marginTop: 10,
              marginBottom: 20,
              borderTop: '1px solid #F3F4F8'
            }}>
            </div>
            {value === '0' ? <div>
              <Form style={{ marginTop: '10px' }}>
                <Flex style={{ paddingLeft: 10, paddingTop: 0, paddingBottom: 10 }}>
                  <div style={{ width: 120, flex: '0 0 auto' }}>
                    <div><img style={{ width: 10, height: 10, padding: 3 }} src={stroke} alt="주민등록번호"/>주민등록번호</div>
                  </div>
                  <Form.Item
                    noStyle
                    label={
                      <div>
                        <img style={{ width: 10, height: 10, padding: 3 }} src={stroke} alt="주민등록번호"/>주민등록번호
                      </div>
                    }
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                  >
                    <Input
                      style={{ width: 200 }}
                      value={juminNumber}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9]/g, '') // 숫자가 아닌 문자를 제거
                        let formattedValue = value

                        if (value.length > 6) {
                          formattedValue = `${value.slice(0, 6)}-${value.slice(6, 13)}`
                        }

                        setJuminNumber(formattedValue)

                      }}
                      placeholder="주민등록번호(XXXXXX-XXXXXXX) 입력"
                      maxLength={14}
                    />
                  </Form.Item>
                </Flex>

              </Form>
            </div> : null}

            <div style={{
              border: '1px solid #BFD3FA',
              borderRadius: '4px',
              width: 580,
              padding: '10px',
              marginTop: '10px',
              backgroundColor: '#E9F0FE',
              display: 'flex',
              fontSize: 14,
              alignItems: 'center'
            }}>
              <i style={{ width: 20, height: 20, marginRight: 5 }} className="svg_icon_20_warning_blue"></i>
              <span style={{ color: '#1A1818' }}>
              고용・산재보험 토탈서비스에 대표자/소속직원으로 로그인 하려면 주민등록번호 입력이 필요해요. <br/>
              대표자/소속직원으로 로그인시엔 대표자/소속직원 선택 후 주민번호를 입력해 주세요.
            </span>
            </div>
          </div> :
          null
        }

      </div>
    </Modal>
  )
}
