import React from 'react'
import styles from "./MultiButton.module.css"

export const MultiButton = (props) => {
  const {
    ref,
    children,
    className,
    style,
    href,
    columnGap,
    ...rest
  } = props

  const basicStyle = {
    ...style,
    columnGap: columnGap ? columnGap : 8,
  }

  return (
    <div 
      ref={ref}
      className={[`${styles.multi_button} ${className}`]}
      style={basicStyle}
      {...rest}
    >
      {children}
    </div>
  )
}
