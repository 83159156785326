import { Modal, Input } from 'antd'
import { MultiButton, Button } from 'components/button'
import React, {  useState } from 'react'
import { callApi } from 'utils';
import styles from "./modal.module.css"
import { Flex } from 'components/flex'

export default function AccountSharingModal(props) {

  const [ phone, setPhone] = useState("");
  const sendMessage =async () =>{
    
      let sessionStorage = window.sessionStorage;
      let opId = sessionStorage.getItem("userId");
  
      const postData = {
        opId: opId ,
        mbId:props&&props.data&&props.data.mbId,
        phone:phone
      };
  
      const response = await callApi('api/platform/hulamSupport', postData);
 
      if (response&&response.result) {
  
        console.log("GOod");
      }
   
    props.handleOk();
  }


  return (
    <Modal
      title={"휴램 접속 계정 전달하기"}
      centered
      open={props.open}
      closeIcon={false}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={
        <MultiButton>
          <Button type="primary" onClick={()=>{sendMessage();}} width={125}>
            전송하기
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            취소하기
          </Button>
        </MultiButton>
      }
    >
      <div className={styles.container}>
        <Flex direction='column'>
          <h3>해당 회사의 담당자에게 휴램에 접속할 수 있도록 정보를 전달할 수 있어요.</h3>
          <p>휴램 아이디: {props&&props.data&&props.data.mbId}, 임시 비밀번호: ●●●●●●</p>
          <p>카카오 알림톡으로 전송됩니다.</p>
        </Flex>
        <div>
          <span>전송할 휴대폰 번호</span>
          <Input value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder="010-1234-5678" width={358} />
        </div>
      </div>
    </Modal>
  )
}
