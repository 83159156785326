import React, { useEffect, useState } from "react";
import { Modal, Input, Form, Col, Row } from 'antd'
import { Button, Flex, SelectBox, NotificationCard, MultiButton, CheckBox } from "components/hulam_platform"
import styles from "./EmployeeAddModal.module.css"
import { alert, callApi, extractNumbers } from "utils";

const generateRandomUpperCase = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const length = 6;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

const phoneConfig = {
  rules: [
    {
      required: true,
      message: '휴대폰 번호를 입력해 주세요.',
    },
  ],
};

export default function EmployeeAddModal(props) {
  const [form] = Form.useForm();
  const initialPassword = generateRandomUpperCase();
  const [alarmYn, setAlarmYn] = useState(true);

  const addEmployee = async (data) => {
    let opId = window.sessionStorage.getItem("userId");

    const postData = {
      opId: opId,
      employeeEmail: data.email + "@" + data.email1,
      password: data.password,
      phone: `${data.phone1}${data.phone2}${data.phone3}`,
      name: data.name,
      role: data.role,
      alarmYn: alarmYn ? "1" : "0"
      // 추가 필드
    };

    const response = await callApi('api/platform/joinEmployee', postData)

    if (response.result) {
      props.handleOk("success");
    } else {

    }
  }

  useEffect(() => {
    generateRandomUpperCase();
  }, [])

  const onFinishFailed = () => {
    alert({ content: "필수값을 입력해주세요." })
  };

  return (
    <Modal
      title={"직원 등록"}
      open={props.modalOpen}
      closeIcon={false}
      width={800}
      onOk={props.handleOk}
      onCancel={props.handleCancel}
      footer={
        <MultiButton>
          <Button onClick={() => form.submit()} width={125}>
            등록
          </Button>
          <Button type="secondary" onClick={props.handleCancel} width={125}>
            닫기
          </Button>
        </MultiButton>
      }
    >
      <div>
        <NotificationCard size="small" message={<>직원 로그인은 이메일 주소, 비밀번호로 로그인돼요.<br />주요한 알림은 휴대폰 번호(카카오 알림톡)로 전달돼요.</>} />
        <Form
          form={form}
          className={styles.form}
          initialValues={{
            'password': initialPassword,
          }}
          onFinish={(value) => addEmployee(value)}
          requiredMark={false}
          onFinishFailed={onFinishFailed}
          labelCol={{ flex: '120px' }}
        >
          <Row gutter={24} style={{ borderBottom: "1px solid #E2E5EA" }}>
            <Col span={12}>
              <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ width: 120 }}>이름</div>
                <Form.Item
                  name="name"
                  noStyle
                  rules={[{ required: true }]}
                >
                  <Input placeholder="입력" style={{ width: 180 }} />
                </Form.Item>
              </Flex>
            </Col>
            <Col span={12}>
              <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ width: 120 }}>담당</div>
                <Form.Item
                  name="role"
                  noStyle
                  rules={[{ required: true }]}
                >
                  <Input placeholder="입력" style={{ width: 180 }} />
                </Form.Item>
              </Flex>
            </Col>
          </Row>
          <Row gutter={24} style={{ borderBottom: "1px solid #E2E5EA" }}>
            <Col span={24}>
              <Form.Item
                noStyle
                required
              >
                <Flex columnGap={10} style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <div style={{ width: 120 }}>휴대폰 번호</div>
                  <Form.Item name="phone1" noStyle {...phoneConfig} getValueFromEvent={extractNumbers}>
                    <Input placeholder="입력" style={{ width: 130 }} maxLength={3} controls={false} />
                  </Form.Item>
                  -
                  <Form.Item name="phone2" noStyle {...phoneConfig} getValueFromEvent={extractNumbers}>
                    <Input placeholder="입력" style={{ width: 130 }} maxLength={4} controls={false} />
                  </Form.Item>
                  -
                  <Form.Item name="phone3" noStyle {...phoneConfig} getValueFromEvent={extractNumbers}>
                    <Input placeholder="입력" style={{ width: 130 }} maxLength={4} controls={false} />
                  </Form.Item>
                </Flex>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} style={{ borderBottom: "1px solid #E2E5EA" }}>
            <Col span={24}>
              <Form.Item
                noStyle
              >
                <Flex direction="column" alignItems="flex-start" style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <Flex>
                    <div style={{ width: 120 }}>이메일 주소</div>
                    <Form.Item
                      name="email"
                      noStyle
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="입력" style={{ width: 155 }}/>
                    </Form.Item>
                    <span style={{ alignSelf: "center" }}>@</span>
                    <Form.Item
                      name="email1"
                      noStyle
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="입력" style={{ width: 155 }}/>
                    </Form.Item>
                    <SelectBox
                      value={""}
                      style={{ width: '118px' }}
                      suffixIcon={<i className='svg_icon_16_search_arrow_down'></i>}
                      options={[
                        {
                          value: "", label: "직접입력"
                        }
                      ]}
                    />
                  </Flex>
                  <span style={{ color: "var(--gray-500)", paddingLeft: 130 }}>이메일 주소가 아이디가 됩니다.</span>
                </Flex>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Flex style={{ paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ width: 120 }}>초기 비밀번호</div>
                <Form.Item name="password" noStyle>
                  <Input.Password bordered={false} style={{ width: 180 }} readOnly />
                </Form.Item>
              </Flex>
            </Col>
          </Row>
        </Form>
      </div>
      <div style={{ margin: "10px 0" }}>
        <CheckBox style={{ width: 'auto' }}
          checked={alarmYn}
          onChange={(e) => setAlarmYn(e.target.checked)}
        >
          직원 등록과 동시에 카카오 알림톡으로 전송됩니다.
        </CheckBox>
      </div>
    </Modal >
  )
}


