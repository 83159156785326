import { Link } from "react-router-dom";
import styles from "./Button.module.css";

export const IconButton = (props) => {
  const {
    htmlRef,
    isDisabled = false,
    children,
    size = "md",
    width,
    className,
    style,
    href,
    ...rest
  } = props

  const contentProps = { children }

  const basicStyle = {
    ...style
  };

  return (
    <>
      {
        href ? (
          <Link
            ref={htmlRef}
            to={href}
            className={[`${styles.hulam_icon_button} ${size ? styles[size] : ''} ${className}`]}
            style={basicStyle}
            {...rest}
            disabled={isDisabled}
          >
            <ButtonContent {...contentProps} />
          </Link>
        ) : (
          <button
            ref={htmlRef}
            type="button"
            className={[`${styles.hulam_icon_button} ${size ? styles[size] : ''} ${className}`]}
            style={basicStyle}
            {...rest}
            disabled={isDisabled}
          >
            <ButtonContent {...contentProps} />
          </button>
        )
      }
    </>
  )
}

function ButtonContent(props) {

  const { children } = props
  return (
    <>
      {children}
    </>
  )
}