import { NotificationCard } from './notification';
import { Flex } from './flex';
import { Button, IconButton } from './button';
import { useEffect, useState } from 'react';
import { DeleteIcon } from './icon';
import dayjs from 'dayjs';

const errorList = [
  {
    id: "test_platform1",
    url: "https://docs.google.com/spreadsheets/d/1UDSnb-OFO1NZhl2sPk1i_wHYGAcj93da5pjh1NL-stc/edit?usp=sharing"
  },
  {
    id: "noel",
    url: "https://docs.google.com/spreadsheets/d/1UDSnb-OFO1NZhl2sPk1i_wHYGAcj93da5pjh1NL-stc/edit?usp=sharing"
  },
  {
    id: "shrm777",
    url: "https://docs.google.com/spreadsheets/d/1ZJSGMcAlNpL6bMmguNeuUSLRZVsXPCU725faqcCyhMg/edit?usp=sharing"
  },
  {
    id: "tehr02",
    url: "https://docs.google.com/spreadsheets/d/1K2nAJEvLpJXVBW6Nf8xACU0aYhq51xEeygDay2tkxY8/edit?usp=sharing"
  },
  {
    id: "starnomu1",
    url: "https://docs.google.com/spreadsheets/d/1RpQZSpIrhT1qrniB-XKlglbxXBjUzO6wVC40y07FrYU/edit?usp=sharing"
  },
  {
    id: "deunhrgg",
    url: "https://docs.google.com/spreadsheets/d/1bUpu4JfvJUy0peR1-ZQ1MhL8rV2zO_j-lZg8DLS1SIg/edit?usp=sharing"
  },
  {
    id: 'cenlabor07',
    url: "https://docs.google.com/spreadsheets/d/1zh1NdLMX6gSV7DUpIKqDgQtR6d0FxaCSzzrQa3t3XLc/edit?usp=sharing"
  },
  {
    id: "trustonelaw",
    url: "https://docs.google.com/spreadsheets/d/1EldBqRdAbEpPf4zuDzfEO3iaK3InGv9jt0yvuKYYBRQ/edit?usp=sharing"
  },
  {
    id: "nomusa22",
    url: "https://docs.google.com/spreadsheets/d/1nVQIhU4Yqjpt3z8QHEalUNjfKQ72azwPdpWq4xthUt8/edit?usp=sharing"
  },
  {
    id: "rodamhr",
    url: "https://docs.google.com/spreadsheets/d/18kduq1v4UQKtvM-A1MAiRGdEeQyUmfKIIr5fzfPcxEM/edit?usp=sharing"
  },
  {
    id: "theklabor1",
    url: "https://docs.google.com/spreadsheets/d/1mDcBwRznX8EBqx2VVx2LFLi9_DeY0cM_iYCJID7dGAg/edit?usp=sharing"
  },
  {
    id: "starnomu2",
    url: "https://docs.google.com/spreadsheets/d/1n58tsBME0QgJ8wkoj-oOSSDqoP16blDZ69CgQxCc80g/edit?usp=sharing"
  },
  {
    id: "noelnomu",
    url: "https://docs.google.com/spreadsheets/d/1wzM9WaQwTWQ80hASSXdRBc6c6OEOT_Ns7BKrWcBsBvk/edit?usp=sharing"
  },
  {
    id: "barolabor",
    url: "https://docs.google.com/spreadsheets/d/1Tm-C_sZl5pqS77ck3I4blrrSK3CEnb07zwKRs0DEZtU/edit?usp=sharing"
  },
  {
    id: "doyonomu",
    url: "https://docs.google.com/spreadsheets/d/1gCgLvxod509hzHDIlcwtmAQU0UOPiHYfWKKt2IMKnOY/edit?usp=sharing"
  }
]

export const ErrorNotiItem = ({id}) => {
  const [errorItem, setErrorItem] = useState(undefined);
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    const getCloseCount = localStorage.getItem("errorNotiClose") || 0;

    if (getCloseCount >= 2 || dayjs().format('YYYY-MM-DD') === "2024-11-18") {
      setIsClose(true);
    }

    if (id) {
      const findItem = errorList.find(item => item.id === id);

      if (findItem) {
        setErrorItem(findItem);
      } else {
        setErrorItem(undefined)
      }
    } else {
      setIsClose(true);
    }

  }, []);


  const openErrorDocs = () => {
    const errorItem = errorList.find(item => item.id === id);

    if (errorItem) {
      window.open(errorItem.url)
    }

  }

  const closeErrorNoti = () => {
    const closeCount = localStorage.getItem("errorNotiClose") || 0;

    localStorage.setItem("errorNotiClose", (Number(closeCount) + 1).toString());

    setIsClose(true);
  }

  if (isClose || errorItem === undefined) {
    return "";
  }

  return (
    <NotificationCard
      type="error"
      style={{
        width: 650,
        marginBottom: 30
      }}
      message={
        <Flex>
          <p style={{ lineHeight: "20px" }}>
            24년 10월 귀속 급여대장에 포함된 사원 중 급여대장의 통상시급과
            사원 상세 급여정보의 통상시급이 다른 사례가 발견되었어요.<br/>
            불일치 사원 목록을 확인 후 정상적인 상태인지 확인 부탁드려요.<br/>
            <a
              href="https://shimmer-vein-8d5.notion.site/1385807a34bf8093840fe0edec51c232"
              target="_blank"
              style={{
                color: "var(--primary-blue-500---main-color, #407DF1)",
                textDecorationLine: "underline",
              }}
              rel="noreferrer"
            >관련 공지사항 보기 ></a>
          </p>
          <Flex style={{ flex: '0 0 auto' }}>
            <Button type="line-gray" size="sm" onClick={() => openErrorDocs(id)}>불일치 사원 목록 확인하기</Button>
            <IconButton style={{ border: "none", background: "none" }} onClick={closeErrorNoti}>
              <DeleteIcon/>
            </IconButton>
          </Flex>
        </Flex>
      }
    />
  )
}