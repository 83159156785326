import createIcon from "components/icon/createIcon"

export const RoundWarningIcon = (props) => {
  return createIcon({
    size: props.size,
    path: <>
      <path d="M10.9079 4.57143C11.3933 3.80952 12.6067 3.80952 13.0921 4.57143L21.8292 18.2857C22.3146 19.0476 21.7078 20 20.737 20H3.26296C2.29218 20 1.68544 19.0476 2.17083 18.2857L10.9079 4.57143Z" fill="#FFCA21" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9997 9.09961C12.4245 9.09961 12.7689 9.44401 12.7689 9.86884V13.5611C12.7689 13.986 12.4245 14.3304 11.9997 14.3304C11.5749 14.3304 11.2305 13.986 11.2305 13.5611V9.86884C11.2305 9.44401 11.5749 9.09961 11.9997 9.09961ZM11.2305 16.2535C11.2305 15.8286 11.5749 15.4842 11.9997 15.4842H12.0089C12.4338 15.4842 12.7782 15.8286 12.7782 16.2535C12.7782 16.6783 12.4338 17.0227 12.0089 17.0227H11.9997C11.5749 17.0227 11.2305 16.6783 11.2305 16.2535Z" fill="#1A1818" />
    </>
  })
} 
